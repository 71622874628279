import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import axiosPrivate from "../../hooks/axiosPrivate";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";
import { Dialog } from "primereact/dialog";
import { Tooltip } from "primereact/tooltip";
import { FaCircleInfo } from "react-icons/fa6";

// Headers for stages DataTableLines component
const DataTableRequests = ({
  lists,
  updateRequestsData

}) => {
  // State variables
  const [newList, setNewList] = useState([]);

   useEffect(() => {
     if (lists) {
       // Add index property to each item for numbering
       setNewList(
         lists.map((item, index) => ({
           ...item,
           index: index + 1,
           tooltipTarget: `production-order-tooltip-${index}`,
           tooltipRemark: `tooltip-remark-${index}`,
         }))
       );
       
     }
   }, [lists]);

  // const [stages, setStages] = useState(stagesData);
  const [stageId, setStageId] = useState(null);
 
  const [showDialog, setShowDialog] = useState(false);
 
  const [lineStageStatus, setlineStageStatus] = useState("0");

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
   
  });


  const onGlobalFilterChange = (event) => {
    const value = event.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
  };

 

  const renderHeader = (options) => {
    const value = filters["global"] ? filters["global"].value : "";
    return (
      <div className='row d-flex justify-content-between align-items-center pl-2 pr-2 '>
        <div className=' text-left'>
          <InputText
            type='search'
            value={value || ""}
            onChange={(e) => onGlobalFilterChange(e)}
            placeholder='Global Search'
          />
        </div>
     
      </div>
    );
  };


  


  const actionTemplate = (rowData) => {   
      return (
        <button
          className='iconBtn complete'
          data-bs-toggle='modal'
          data-bs-target='#confirmModal'
          onClick={() => {
            setStageId(rowData?.line_stage_id);
            
            setShowDialog(true);
          }}
        >
          <svg
            width='12'
            height='9'
            viewBox='0 0 12 9'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M1 4L4.5 7.5L10.5 1.5'
              stroke='white'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </button>
      );
          
      
  };

  

 
  const header = renderHeader();

  const renderProductionOrderBody = (rowData) => (
    <>
      <span
        id={`production-order-tooltip-${rowData?.index}`}
        className='p-overlay-badge'
      >
        {rowData?.prod_order}
        <FaCircleInfo
          className='ml-3 mb-1'
          style={{ cursor: "pointer" }}
        />
      </span>
      <Tooltip
        target={`#${rowData.tooltipTarget}`}
        position='right'
        content={rowData?.LineDesc}
      />
    </>
  );  const onHideDialog = () => {
    setShowDialog(false);
    setStageId(null);
  };

   const remarksBodyTemplate = (rowData) => {
     return (
       <>
         <span
           id={rowData?.tooltipRemark}
           className='p-overlay-badge'
         >
           {rowData?.remarks ? rowData?.remarks.slice(0, 25) + "..." : "-"}
           <FaCircleInfo
             className='ml-1'
             style={{ cursor: "pointer" }}
           />
         </span>
         <Tooltip
           target={`#${rowData.tooltipRemark}`}
           mouseTrack
           mouseTrackLeft={10}
           content={rowData?.remarks}
         />
       </>
     );
  };
  
   const supervisorBodyTemplate = (rowData) => {
         return <span>{rowData?.name ? rowData?.name : "-"}</span>;
  };
  
  const PlannedEndTemplate = (rowData) => {
    return (
      <span>
        {rowData?.planned_end
          ? new Date(rowData.planned_end).toLocaleDateString("en-GB")
          : "-"}
      </span>
    );
  };

  const handleStageUpdate = async () => {
    try {
      const response = await axiosPrivate.put(
        `tracking_stages/update_reopen_request/${stageId}`
       
      );

      if (response?.status === 200) {
        showSuccessToast("Stage Status Updated Successfully");
        updateRequestsData();              
      }

      if (lists?.length === 1) {
        window.location.reload();
      }
    } catch (err) {
      
      showErrorToast(err.message);
    } finally {
      setShowDialog(false);
      setStageId(null);
    }
  }

  return (
    <>
      <Dialog
        header='Confirmation'
        visible={showDialog}
        style={{ width: "30vw" }}
        onHide={onHideDialog}
      >
        <div className='p-d-flex p-ai-center p-dir-col'>
          <p>Are you sure you want to re-open this stage!</p>
          
          <div className='p-d-flex p-ai-center'>
            <button
              className='btn btn-primary'
                onClick={handleStageUpdate}
            >
              Yes
            </button>
            <button
              className='btn btn-secondary ml-3'
              onClick={onHideDialog}
            >
              No
            </button>
          </div>
        </div>
      </Dialog>
      

      <DataTable
        value={newList}
        header={header}
        className='p-2'
        rows={20}
        size='small'
        paginator
        rowsPerPageOptions={[20, 40, 60, 80]}
        paginatorTemplate={
          "RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
        }
        currentPageReportTemplate={"{first} to {last} of {totalRecords}"}
        filters={filters}
        onFilter={(e) => setFilters(e.filters)}
        dataKey='line_stage_id'
      >
        {/* Columns definition */}

        <Column
          field='stage_name'
          header='Stage'
          // sortable={true}
        />

        <Column
          field='order_num'
          header='Sales Order'
          headerStyle={{ width: "15%", textAlign: "left" }}
          // sortable={true}
        />

        <Column
          field='prod_order'
          header='Production Order'
          headerStyle={{ width: "15%", textAlign: "left" }}
          body={renderProductionOrderBody}
          // sortable={true}
        />

        <Column
          field='supervisor'
          header='Stage Supervisor'
          body={supervisorBodyTemplate}
          headerStyle={{ width: "10%" }}
          // sortable
        />
        <Column
          field='new_remarks'
          header='Remarks'
          body={remarksBodyTemplate}
         
        />
        <Column
          field='new_planned_end'
          header='Deadline'
          body={PlannedEndTemplate}
          //   headerStyle={{ width: "8%", textAlign: "left" }}
          // bodyStyle={{ textAlign: "left" }}
          // sortable
        />

        <Column
          field='action'
          header='Action'
          body={actionTemplate}
        />
      </DataTable>
    </>
  );
};

export default DataTableRequests;
