import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaEdit, FaTrash } from "react-icons/fa";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";
import axiosPrivate from "../../hooks/axiosPrivate";
import { usePermissions } from "../../context/permissions/PermissionsProvider";
import { useLoader } from "../../context/Loader/LoaderProvider";
import Loader from "../../context/Loader/Loader";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext"; // Import InputText component for search functionality
import { FilterMatchMode } from "primereact/api"; // Import FilterMatchMode for configuring search behavior
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
const Company = () => {
  // State for storing company data
  const [companiesData, setCompaniesData] = useState([]);
  const { isLoading, setIsLoading } = useLoader();
  const [companyId, setCompanyId] = useState(null);
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);  // Get the isLoading state and setIsLoading function from LoaderProvider
  const { authPermissions } = usePermissions();
  /**
   * State for global search filter on the company data table.
   * The filter is configured to use the CONTAINS match mode, which means
   * the search will match any part of the company data.
   */
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }, // State for global search filter
  });



  useEffect(() => {
    if (companyId) {
      setDeleteConfirmationVisible(true);
    }
  }, [companyId]);  
  /**
   * Deletes a company from the backend and updates the company data in the state.
   *
   * @async
   * @function companyDeleteHandler
   * @param {number} id - The ID of the company to be deleted.
   * @returns {Promise<void>} - Resolves when the company has been deleted and the state has been updated.
   */
  const companyDeleteHandler = async (id) => {
    try {
      const response = await axiosPrivate.delete(`company/${id}`);
      if (response.status === 200) {
        showSuccessToast("Company deleted successfully");
        fetchData();
      }
    } catch (error) {
      showErrorToast(error?.response?.data?.errors[0]?.msg || error.message);
    } finally {
      setCompanyId(null);
    }
  };
  // Function to fetch company data
  /**
   * Fetches the company data from the backend and updates the state with the response.
   * This function is called when the component mounts to initialize the company data.
   *
   * @async
   * @function fetchData
   * @returns {Promise<void>} - Resolves when the data has been fetched and the state has been updated.
   */
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get("company");
      if (response.status === 200) {
        setCompaniesData(response.data?.data);
      }
    } catch (error) {
      if (error.response?.status !== 404) {
        showErrorToast(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Prepare data for rendering in DataTable
  /**
   * Maps the companiesData array to an array of objects with additional properties, including an index and an action column containing edit and delete buttons.
   *
   * @param {Object[]} companiesData - The array of company data objects.
   * @param {number} companiesData[].id - The ID of the company.
   * @param {string} companiesData[].name - The name of the company.
   * @param {string} companiesData[].email - The email of the company.
   * @param {string} companiesData[].phone - The phone number of the company.
   * @param {string} companiesData[].address - The address of the company.
   * @param {boolean} authPermissions.includes - Checks if the user has the "Company-Update" permission.
   * @returns {Object[]} - An array of objects with the following properties: id, name, email, phone, address, index, action.
   */
  const companyListData = companiesData?.map((value, index) => {
    let buttons = [];

    // Add Edit button if the user has permission
    if (authPermissions?.includes("Company-Update")) {
      buttons.push(
        <Link
          key={`editButton_${value.id}`}
          to={`/company/edit/${value.id}`}
          className='btn btn-link'
          style={{ border: "none", background: "none", padding: "0" }}
          title='Edit'
        >
          <FaEdit
            color='green'
            size={13}
          />
        </Link>
      );
    }

    return { ...value, index: index + 1, action: buttons };
  });

  // Function to handle global filter change
  const onGlobalFilterChange = (event) => {
    const { value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      global: { value: value, matchMode: FilterMatchMode.CONTAINS }, // Set the value and matchMode for global search
    }));
  };

  // Render global search input
  const renderHeader = () => {
    const value = filters["global"] ? filters["global"].value : "";

    return (
      <div className='d-flex justify-content-between w-100'>
        {authPermissions?.includes("Company-Create") && ( // Render company management section if user has permission
          <Link
            to='add'
            className='text-decoration-none'
          >
            <button className='btn btn-primary'>
              <i className='fe fe-plus mr-2' />
              Add
            </button>
          </Link>
        )}
        <InputText
          type='search'
          value={value || ""}
          onChange={onGlobalFilterChange}
          placeholder='Global Search'
        />
      </div>
    );
  };

  const header = renderHeader(); // Call the renderHeader function to get the search input component


  const deleteBodyTemplate = (rowData) => {
    return (
      <button
        type='button'
        onClick={() => {
          setCompanyId(rowData.id);
        }}
        title='Delete'
        style={{ border: "none", background: "none", padding: "6px", textAlign: "left" }}
      >
        <FaTrash
          color='red'
          size={13}

        />
      </button>
    );
  }

  return (
    <>
      {isLoading ? ( // Render Loader component based on isLoading state
        <Loader />
      ) : (
        <div className='content'>
          <div className='page-header'>
            <h4 className='m-1 pl-3'>Companies</h4>
          </div>

          <div className='card'>
            <div className='card-body p-0'>
              <div className='table-responsive'>
                <DataTable // Render DataTable component
                  paginator
                  rowsPerPageOptions={[10, 20, 30, 50]}
                  paginatorTemplate='RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink'
                  currentPageReportTemplate='{first} to {last} of {totalRecords}'
                 
                  rows={10} // Set number of rows per page
                  value={companyListData}
                  dataKey='id'
                  header={header} // Pass the search input component as header
                  filters={filters} // Pass filters state for search functionality
                  onFilter={(e) => setFilters(e.filters)} // Update filters state on filter change
                >
                  <Column
                    field='index'
                    header='Sr. No.'
                    sortable
                    bodyStyle={{ textAlign: "left" ,paddingLeft: '1%' }}
                    style={{ width: "8%" }}
                  />
                  <Column
                    field='name'
                    header='Company'
                    sortable
                  />
                  <Column
                    field='code'
                    header='Company Code'
                    sortable
                  />
                  <Column
                    field='action'
                    header='Action'
                    headerStyle={{ width: "3%", paddingLeft: "65px" }}
                    bodyStyle={{ textAlign: "right" }}
                    />

                    {(authPermissions?.includes("Company-Delete")) ? <Column
                    field='delete_handler'
                    body={deleteBodyTemplate}
                    headerStyle={{ width: "15%" }}
                    bodyStyle={{ textAlign: "left" }}
                  /> : null}
                    
                  
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      )}
      <Dialog
        visible={deleteConfirmationVisible}
        onHide={() => {
          setDeleteConfirmationVisible(false);
          setCompanyId(null);
        }}
        header='Confirmation'
        footer={
          <>
            <Button
              label='Cancel'
              className='p-button-text mr-2'
              onClick={() => {
                setCompanyId(null);
                setDeleteConfirmationVisible(false);
              }}
            />
            <Button
              label='Delete'
              className='p-button-danger'
              onClick={() => {
                companyDeleteHandler(companyId);
                setDeleteConfirmationVisible(false);
              }}
            />
          </>
        }
      >
        <p>Are you sure you want to delete this Company?</p>
      </Dialog>
    </>
  );
};

export default Company;
