import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";
import PermissionTableTd from "./PermissionTableTd";
import axiosPrivate from "../../hooks/axiosPrivate";

const RolePermission = () => {
  // Get user ID from route parameters
  const { id } = useParams();
  const navigate = useNavigate();

  // State variables
  const [permissions, setPermissions] = useState([]);
  const [permissionDistinctName, setPermissionDistinctName] = useState([]);
  const [rolePermissionIds, setRolePermissionIds] = useState([]);
  const [isPermission, setIsPermission] = useState([]);

  // Fetch permissions and permission distinct names on component mount
  useEffect(() => {
    permissionsDinstict();
  }, []);

  // Fetch user permissions whenever user ID changes
  useEffect(() => {
    hasPermissions();
  }, [id]);

  // Fetch all permissions and distinct permission names
  const permissionsDinstict = async () => {
    try {
      const response = await axiosPrivate.get("permissions");
      setPermissions(response?.data?.data || []);
    } catch (error) {
      showErrorToast("Error fetching permissions!");
    }

    try {
      const response = await axiosPrivate.get("permissions/distinct/name");
      setPermissionDistinctName(response?.data?.data || []);
    } catch (error) {
      showErrorToast("Error fetching distinct permission names!");
    }
  };

  // Fetch user-specific permissions
  const hasPermissions = async () => {
    try {
      const response = await axiosPrivate.get(`role_has_permissions/${id}`);
      if (response?.data?.data) {
        const rolePermissionsArray =
          response?.data?.data?.map((item) => item.permission_id.toLocaleString()) || [];
        setRolePermissionIds(rolePermissionsArray);
        setIsPermission(response?.data?.data || []);
      }

      if (response?.code === 404) {
        showErrorToast("No role permissions found! Kindly add permissions.");
      }
    } catch (error) {
     
      if (error?.response?.status !== 404) {
      
        showErrorToast("Error fetching role permissions!");
      
      }

        
    }
  };


 
  // Handle form submission to update user permissions
  const submitHandler = async (event) => {
    event.preventDefault();
    const values = {
      role_id: id,
      permission_ids: rolePermissionIds,
    };

    try {
      await axiosPrivate.post(`role_has_permissions`, values);
      navigate("/roles");
       window.location.reload();
      showSuccessToast("Permissions updated successfully");
    } catch (error) {
      showErrorToast("Error updating permissions!");
    }
  };

  // Modify user permissions array based on user action
  const modifyPermissionIdArray = async (permission_id, action) => {
    setRolePermissionIds((prevIds) => {
      if (action === false) {
        return prevIds.filter((id) => id !== permission_id);
      } else {
        return [...prevIds, permission_id];
      }
    });
  };

  // Title for permission actions
  const title = ["CREATE", "LIST", "UPDATE", "DELETE"];

  // Generate rows for permission table
  const rows = permissionDistinctName?.map((distinct, index) => {
    // Filter permissions based on distinct name
    const distinctPermissions = permissions?.filter(
      (permission) => permission.name === distinct.name
    );

    const createPermission = distinctPermissions?.find(
      (permission) => permission.action === "Create"
    );

    const listPermission = distinctPermissions?.find(
      (permission) => permission.action === "List"
    );

    const updatePermission = distinctPermissions?.find(
      (permission) => permission.action === "Update"
    );

    const deletePermission = distinctPermissions?.find(
      (permission) => permission.action === "Delete"
    );

    return (
      <tr key={index}>
        <td className='text-center'>{distinct?.name}</td>
        {/* Map over the filtered permissions array */}
        <PermissionTableTd
          id={createPermission?.id}
          modifyPermissionIdArray={modifyPermissionIdArray}
          checked={rolePermissionIds.includes(
            createPermission?.id.toLocaleString()
          )}
          tdKey={index}
        />
        <PermissionTableTd
          id={listPermission?.id}
          modifyPermissionIdArray={modifyPermissionIdArray}
          checked={rolePermissionIds.includes(
            listPermission?.id.toLocaleString()
          )}
          tdKey={index}
        />
        <PermissionTableTd
          id={updatePermission?.id}
          modifyPermissionIdArray={modifyPermissionIdArray}
          checked={rolePermissionIds.includes(
            updatePermission?.id.toLocaleString()
          )}
          tdKey={index}
        />
        <PermissionTableTd
          id={deletePermission?.id}
          modifyPermissionIdArray={modifyPermissionIdArray}
          checked={rolePermissionIds.includes(
            deletePermission?.id.toLocaleString()
          )}
          tdKey={index}
        />
      </tr>
    );
  });

  return (
    <div className='content'>
      <div className='card'>
        <form onSubmit={submitHandler}>
          <div className='page-header'>
            <h4 className='m-1 pl-3'>Role Permissions</h4>
          </div>
          <div className='card-body'>
            <div className='row clearfix'>
              <div className='col-12'>
                <div className='table-responsive'>
                  <table className='table table-striped'>
                    <thead>
                      <tr>
                        <th className='text-center'>MASTERS</th>
                        {title.map((name, key) => (
                          <th
                            key={key}
                            className='text-center'
                          >
                            {name}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>{rows}</tbody>
                  </table>
                </div>
                <div className='text-end'>
                  <button
                    type='button'
                    id='role_permissions_close_button'
                    className='btn btn-secondary'
                    data-dismiss='modal'
                    onClick={() => {
                      navigate("/roles");
                    }}
                    style={{ margin: 5 }}
                  >
                    CLOSE
                  </button>
                  <button
                    type='submit'
                    id='role_permissions_update_button'
                    className='btn btn-primary float-right'
                  >
                    UPDATE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RolePermission;
