import React from "react";
import { FaDownload} from "react-icons/fa";
import { FcFile } from "react-icons/fc";
import "./SupervisorUploadPage.css"; // Import CSS file for custom styling
import Nodata from "../../assets/images/noData.png";
import { Link } from "react-router-dom";

const SupervisorUploadPage = ({ documents }) => {
  const handleDownload = async (name) => {
    const imageURL = process.env.REACT_APP_MEDIA_URL + name; // Change the URL accordingly
    try {
      const response = await fetch(imageURL);
      const responseData = await response.arrayBuffer(); // Convert response to ArrayBuffer
      const blob = new Blob([responseData], {
        type: "image/jpg",
      }); // Create Blob object from ArrayBuffer
      const fileName = name || "downloaded-file.jpg"; // Default filename
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error fetching the file:", error);
    }
  };

  return (
    <>
      {documents && documents.length > 0 ? (
        <div className='card'>
          {/* Documents */}
          <div className='card-body row p-3'>
            {documents.map((document, index) => (
              // Check if the document matches selected ProductionOrders

              <div
                className='document-card col-md-2 col-sm-4'
                key={index}
              >
                {/* Display document preview or icon */}
                <div className='document-preview'>
                  {document.type === "image" ? (
                    <Link
                      to={process.env.REACT_APP_MEDIA_URL + document?.document}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <img
                        src={
                          process.env.REACT_APP_MEDIA_URL + document?.document
                        }
                        alt={`Document ${index + 1}`}
                      />
                    </Link>
                  ) : (
                    <Link
                      to={process.env.REACT_APP_MEDIA_URL + document?.document}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <div className='file-icon'>
                        <FcFile />
                      </div>
                    </Link>
                  )}
                  {/* Show file name */}
                  <p className='file-name'>{document.document}</p>
                </div>
                {/* Options on hover */}
                <div className='options'>
                  <Link
                    to={process.env.REACT_APP_MEDIA_URL + document?.document}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <FaDownload
                      className='download-icon'
                      // onClick={() => handleDownload(document?.document)}
                    />
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div
          className='card text-center'
          style={{ height: "calc(100vh - 170px)" }}
        >
          <img
            src={Nodata}
            alt='No data found'
            style={{
              margin: "auto",
            }}
          />
        </div>
      )}
    </>
  );
};

export default SupervisorUploadPage;
