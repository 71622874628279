import React, { useEffect, useState } from "react";
import "./job.module.css";
import { useParams } from "react-router-dom";
import axiosPrivate from "../../hooks/axiosPrivate";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";
import { useLoader } from "../../context/Loader/LoaderProvider";
import Loader from "../../context/Loader/Loader";
import { TabPanel, TabView } from "primereact/tabview";
import UploadsViewPage from "./UploadsViewPage";
import DataTableLines from "../../components/DataTableLines/DataTableLines";
import { FcClearFilters } from "react-icons/fc";
import Select from "react-select";
import DataTableStages from "../../components/DataTableStages/DataTableStages";
import { AiOutlineDelete } from "react-icons/ai";

const JobView = () => {
  const [jobData, setJobData] = useState([]);
  const { isLoading, setIsLoading } = useLoader();
  const [supervisors, setSupervisors] = useState([]);

  const { id } = useParams();
  const orderNum = id;

  const { userId } = JSON.parse(localStorage.getItem("userData"));

  // State variables to store filters and filtered jobs
  const [filterProductGroup, setFilterProductGroup] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [filterSupervisorLines, setFilterSupervisorLines] = useState([]);
  // const [selectedOrderLines, setSelectedOrderLines] = useState([]);
  const [selectedProductionOrders, setSelectedProductionOrders] = useState([]);
  
  
  const [filterStatus, setFilterStatus] = useState([]);
  const [stagesData, setStagesData] = useState([]);
  const [filterSupervisorStages, setFilterSupervisorStages] = useState([]);
  const [filterProductionOrder, setFilterProductionOrder] = useState([]);
  const [filteredStages, setFilteredStages] = useState([]);



 
  const [supervisorIdLines, setSupervisorIdLines] = useState(null);
  const [runningStages, setRunningStages] = useState(null);
  const [releaseDate, setReleaseDate] = useState(null);
  const [lineStatus, setLineStatus] = useState(null);
   const [productionOrder, setProductionOrder] = useState(null);

  // Get unique order lines and production orders from job lines data

  const productCategories = Array.from(
    new Set(jobData?.map((job) => job.ProdGroup))
  );
 
  const productionOrders = Array.from(
    new Set(jobData?.map((job) => job.ProductionOrder))
  ).map((productionOrder) => ({
    value: productionOrder,
    label: productionOrder,
  }));



  const fetchJobData = async () => {
    try {
      if (orderNum) {
        setIsLoading(true); // Set isLoading to true before fetching data
        const response = await axiosPrivate.get(`job/${orderNum}`);
        setJobData(response?.data?.data);
        applyFiltersLines(null, response?.data?.data);
        const newData = response?.data?.data?.map((line) => line.stages).flat();
        setFilteredStages(newData);
        setStagesData(newData);
        applyFiltersStages(null, newData);
      }
    } catch (err) {
      showErrorToast(err.message);
    } finally {
      setIsLoading(false); // Set isLoading to false after fetching data (whether success or error)
    }
  };

  useEffect(() => {
    fetchJobData();
  }, []);

  const fetchSupervisorsData = async () => {
    try {
      const response = await axiosPrivate.get(`users/supervisors/${userId}`);
      if (response.status === 200) {
        setSupervisors(response?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };



  useEffect(() => {
    fetchSupervisorsData();
  }, []);

  const applyFiltersLines = (e, data = jobData) => {
    let filteredData = data;

    // Apply product group filter
    if (filterProductGroup.length > 0) {
      filteredData = filteredData.filter((job) =>
        filterProductGroup.includes(job.ProdGroup)
      );
    }

    // Apply supervisor filter
    if (filterSupervisorLines.length > 0) {
      filteredData = filteredData.filter((stage) =>
        filterSupervisorLines
          .map((supervisor) => Number(supervisor.value))
          .includes(stage.assigned_user)
      );
    }

    // Apply production order filter
    if (selectedProductionOrders.length > 0) {
      filteredData = filteredData.filter((job) =>
        selectedProductionOrders.includes(job.ProductionOrder)
      );
    }

    // Update filtered jobs state
    setFilteredJobs(filteredData);
  };

  // Function to clear filters
  const clearFiltersLines = () => {
    setFilterProductGroup([]);
    setFilterSupervisorLines([]);
    setSelectedProductionOrders([]);
    setFilteredJobs(jobData);
  };



  const statusOptions = [
    ...new Set(filteredStages?.map((stage) => stage.status)),
  ];

  // const supervisorOptions = ["Ganga Singh", "Arun Bolia"];
  const productionOrderOptions = Array.from(
    new Set(filteredStages?.map((stage) => stage.production_order))
  );

  const applyFiltersStages = (e, data = stagesData) => {
    let filteredData = data;

    // Apply status filter
    if (filterStatus.length > 0) {
      filteredData = filteredData.filter((stage) =>
        filterStatus.includes(stage.status)
      );
    }

    // Apply supervisor filter
    if (filterSupervisorStages.length > 0) {
      filteredData = filteredData.filter((stage) =>
        filterSupervisorStages
          .map((supervisor) => Number(supervisor.value))
          .includes(stage.assigned_user)
      );
    }

    // Apply production order filter
    if (filterProductionOrder.length > 0) {
      filteredData = filteredData.filter((stage) =>
        filterProductionOrder.includes(stage.production_order)
      );
    }

    setFilteredStages(filteredData);
  };

  const clearFiltersStages = () => {
    setFilterStatus([]);
    setFilterSupervisorStages([]);
    setFilterProductionOrder([]);
    setFilteredStages(stagesData);
  };

  const stageDeleteHandler = async (id) => {
    try {
      const response = await axiosPrivate.delete(`stage/line/${id}`);
      if (response.status === 200) {
        showSuccessToast("Stage deleted successfully");
        updateJobData();
      }
    } catch (error) {
      showErrorToast(
        error?.response?.data?.errors?.length
          ? error?.response?.data?.errors[0]?.msg
          : "Something went wrong!"
      );
    }
  };

    const updateJobData = () => {
      fetchJobData();
    };

  const stagesListData = filteredStages?.map((value) => {
    let buttons = [];

    // Add view button
    buttons.push(
      <AiOutlineDelete
        key={`deleteButton_${value.id}`}
        onClick={() => stageDeleteHandler(value?.id)}
        size={30}
        style={{
          cursor: "pointer",
          padding: "5px",

          backgroundColor: "initial",
          borderRadius: "5px",
        }}
        onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "#f0f0f0")}
        onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "initial")}
        color='#ff0000'
      />
    );

    value["action"] = buttons.length > 0 ? buttons : "-";

    return value;
  });

  return (
    <>
      {/* Conditionally render Loader component based on isLoading state */}
      {isLoading ? (
        <Loader />
      ) : (
        <TabView>
          <TabPanel header='Job Info'>
            <div className='card bg-light '>
              <div className='card-body'>
                <div
                  className='row col-12 ml-1'
                  style={{ color: "black" }}
                >
                  <div className='col-md-4 '>
                    <p>Order Type: {jobData[0]?.OrderType}</p>
                    <p>Customer ID: {jobData[0]?.CustID}</p>
                    <p>Country: {jobData[0]?.Country}</p>
                  </div>
                  <div className='col-md-4'>
                    <p>
                      Order Date:{" "}
                      {new Date(jobData[0]?.OrderDt).toLocaleDateString(
                        "en-GB"
                      )}
                    </p>
                    <p>Customer Name: {jobData[0]?.CustomerName}</p>
                    <p>Order Number: {jobData[0]?.OrderNum}</p>
                  </div>
                  <div className='col-md-4'>
                    <p>State: {jobData[0]?.State}</p>
                    <p>Office: {jobData[0]?.Office}</p>
                    <p>Engineer: {jobData[0]?.Engineer}</p>
                    {/* <p>
                      Ship Date:{" "}
                      {new Date(jobData[0]?.ShipDt).toLocaleDateString("en-GB")}
                    </p> */}
                  </div>
                </div>

                {/* <JobLines
                  jobData={jobData}
                  updateJobData={updateJobData}
                  supervisors={supervisors}
                /> */}

                {/* JobLines Section */}
                <div>
                  <div className='col-12'>
                    <div className='card'>
                      <div className='card-header'>
                        <h5>Lines</h5>
                      </div>
                      <div className='d-flex flex-column card-body'>
                        <div className='filter-section row pl-3 pr-3 d-flex flex-column  rounded-3  mb-2 h-100'>
                          <div className='d-flex justify-content-between align-items-center col-12'>
                            <div className='form-group col-md'>
                              <label className='form-label'>
                                Product Group:
                              </label>
                              <Select
                                options={productCategories.map((group) => ({
                                  value: group,
                                  label: group,
                                }))}
                                value={filterProductGroup.map((group) => ({
                                  value: group,
                                  label: group,
                                }))}
                                isMulti
                                onChange={(selectedOptions) => {
                                  setFilterProductGroup(
                                    selectedOptions.map(
                                      (option) => option.value
                                    )
                                  );
                                }}
                              />
                            </div>
                            {/* Supervisor filter */}
                            <div className='form-group  col-md'>
                              <label className='form-label'>Supervisor:</label>
                              {/* Supervisor multi-select */}
                              <Select
                                options={supervisors}
                                isMulti
                                value={filterSupervisorLines?.map(
                                  (supervisor) => ({
                                    value: supervisor.value,
                                    label: supervisor.label,
                                  })
                                )}
                                onChange={(selectedOptions) => {
                                  const filteredOptions = selectedOptions?.map(
                                    (option) => ({
                                      value: option.value,
                                      label: option.label,
                                    })
                                  );
                                  setFilterSupervisorLines(
                                    filteredOptions ?? []
                                  );
                                }}
                              />
                            </div>

                            <div className='form-group col-md'>
                              <label className='form-label'>
                                Production Order:
                              </label>
                              <Select
                                options={productionOrders}
                                value={selectedProductionOrders.map(
                                  (option) => ({
                                    value: option,
                                    label: option,
                                  })
                                )}
                                isMulti
                                onChange={(selectedOptions) => {
                                  setSelectedProductionOrders(
                                    selectedOptions?.map(
                                      (option) => option.value
                                    )
                                  );
                                }}
                              />
                            </div>
                            <div className='d-flex col-auto justify-content-between align-items-center'>
                              <button
                                className='btn btn-primary mt-2 '
                                onClick={applyFiltersLines}
                              >
                                Apply
                              </button>
                              <div>
                                <FcClearFilters
                                  size={25}
                                  onClick={clearFiltersLines}
                                  style={{
                                    cursor: "pointer",
                                    marginLeft: "5px",
                                    marginTop: "5px",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='table-responsive col-12 mb-4 '>
                          <DataTableLines
                            updateJobData={updateJobData}
                            lists={filteredJobs}
                            // headers={headers}
                            supervisors={supervisors}
                            supervisorId={supervisorIdLines}
                            setSupervisorId={setSupervisorIdLines}
                            productionOrder={productionOrder}
                            setProductionOrder={setProductionOrder}
                            runningStages={runningStages}
                            setRunningStages={setRunningStages}
                            releaseDate={releaseDate}
                            setReleaseDate={setReleaseDate}
                            lineStatus={lineStatus}
                            setLineStatus={setLineStatus}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <StagesSection
                  jobData={jobData}
                  updateJobData={updateJobData}
                  supervisors={supervisors}
                /> */}

                {/* Stages Section */}

                <div className='col-12'>
                  <div className='card'>
                    <div className='card-header '>
                      <h5>Stages</h5>
                    </div>

                    <div className='card-body d-flex flex-column'>
                      <div
                        className='filter-section  pl-3 pr-3 d-flex flex-column rounded-3  mb-2 h-100'
                        style={{ borderRadius: "5px" }}
                      >
                        <div className='d-flex justify-content-between align-items-center row'>
                          {/* Status filter */}
                          <div className='form-group  col-md'>
                            <label className='form-label'>Status:</label>

                            <Select
                              options={statusOptions?.map((status) => ({
                                value: status,
                                label:
                                  status === 0
                                    ? "Pending"
                                    : status === 1
                                    ? "In Progress"
                                    : status === 2
                                    ? "Hold"
                                    : status === 3
                                    ? "Resume"
                                    : status === 4
                                    ? "Completed"
                                    : status === 5
                                    ? "Re-open"
                                    : "Pending",
                              }))}
                              isMulti
                              value={filterStatus?.map((value) => ({
                                value: value,
                                label:
                                  value === 0
                                    ? "Pending"
                                    : value === 1
                                    ? "In Progress"
                                    : value === 2
                                    ? "Hold"
                                    : value === 3
                                    ? "Resume"
                                    : value === 4
                                    ? "Completed"
                                    : value === 5
                                    ? "Re-open"
                                    : "Pending",
                              }))}
                              onChange={(selectedOptions) => {
                                setFilterStatus(
                                  selectedOptions
                                    ? selectedOptions?.map(
                                        (option) => option.value
                                      )
                                    : []
                                );
                              }}
                            />
                          </div>
                          {/* Supervisor filter */}
                          <div className='form-group  col-md'>
                            <label className='form-label'>Supervisor:</label>
                            {/* Supervisor multi-select */}
                            <Select
                              options={supervisors}
                              isMulti
                              value={filterSupervisorStages?.map(
                                (supervisor) => ({
                                  value: supervisor.value,
                                  label: supervisor.label,
                                })
                              )}
                              onChange={(selectedOptions) => {
                                const filteredOptions = selectedOptions?.map(
                                  (option) => ({
                                    value: option.value,
                                    label: option.label,
                                  })
                                );
                                setFilterSupervisorStages(
                                  filteredOptions ?? []
                                );
                              }}
                            />
                          </div>
                          {/* Production Order filter */}
                          <div className='form-group  col-md'>
                            <label className='form-label'>
                              Production Order:
                            </label>
                            {/* Production Order multi-select */}
                            <Select
                              options={productionOrderOptions?.map((order) => ({
                                value: order,
                                label: order,
                              }))}
                              value={filterProductionOrder?.map((order) => ({
                                value: order,
                                label: order,
                              }))}
                              isMulti
                              onChange={(selectedOptions) => {
                                setFilterProductionOrder(
                                  selectedOptions
                                    ? selectedOptions?.map(
                                        (option) => option.value
                                      )
                                    : []
                                );
                              }}
                            />
                          </div>
                          {/* Apply filters button */}
                          <div className='d-flex col-auto justify-content-between align-items-center'>
                            <button
                              className='btn btn-primary  mt-2'
                              onClick={applyFiltersStages}
                            >
                              Apply
                            </button>
                            <FcClearFilters
                              size={25}
                              onClick={clearFiltersStages}
                              style={{
                                cursor: "pointer",
                                marginLeft: "5px",
                                marginTop: "5px",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='table-responsive col-12 mb-4 '>
                        <DataTableStages
                          lists={stagesListData}
                          linesData={jobData}
                          updateJobData={updateJobData}
                          supervisors={supervisors}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel header='Uploads'>
            {/* Render UploadsViewPage component */}
            <UploadsViewPage orderNum={orderNum} />
          </TabPanel>
        </TabView>
      )}
    </>
  );
};

export default JobView;
