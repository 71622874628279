import React, { useState, useEffect } from "react";
import axiosPrivate from "../../hooks/axiosPrivate";

import { FaDownload, FaTrash } from "react-icons/fa";
import { RxUpdate } from "react-icons/rx";
import { FcFile } from "react-icons/fc";
import Select from "react-select";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import "./UploadsViewPage.css"; // Import CSS file for custom styling
import { showSuccessToast } from "../../utils/Toaster";
import Nodata from "../../assets/images/noData.png";
import { Link } from "react-router-dom";

const UploadsViewPage = ({ orderNum }) => {
  const [documents, setDocuments] = useState([]);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);
  const [revisedDocuments, setRevisedDocuments] = useState(null); // State to store the revised document

  // State for selected ProductionOrders
  const [selectedProdOrders, setSelectedProdOrders] = useState([]);

  // Fetch documents for the specified production order
  const fetchDocuments = async () => {
    try {
      const response = await axiosPrivate.get(`docs/${orderNum}`);

      if (response.status === 200) {
        const data = response.data?.data;

        const newData = data.map((doc) => ({
          ...doc,
          type:
            doc.document.includes(".jpg") || doc.document.includes(".png")
              ? "image"
              : "file",
        }));

        setDocuments(newData.filter((doc) => !doc?.revision));
        setRevisedDocuments(newData.filter((doc) => doc?.revision));
      }
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, [orderNum]);

  // Options for ProductionOrder filter
  const prodOrderOptions = [
    ...new Set(
      documents
        .filter((doc) => doc.ProductionOrder !== null)
        .map((doc) => doc.ProductionOrder)
    ),
  ].map((prod_order) => ({
    value: prod_order,
    label: prod_order,
  }));

  // Function to handle document deletion
  const handleDeleteDocument = async () => {
    try {
      // Make API call to delete document
      const response = await axiosPrivate.delete(`docs/${selectedDoc?.id}`);

      if (response.status === 200) {
        // Show success message
        showSuccessToast("Document deleted successfully");
      }

      // Remove the deleted document from the state
      setDocuments((prevDocuments) =>
        prevDocuments.filter((doc) => doc.id !== selectedDoc.id)
      );

      // Close the confirmation dialog
      setDeleteConfirmationVisible(false);
    } catch (error) {
      console.error("Error deleting document:", error);
    }
  };

  const handleDownload = async (name) => {
    const imageURL = process.env.REACT_APP_MEDIA_URL + name; // Change the URL accordingly
    try {
      const response = await fetch(imageURL);
      const responseData = await response.arrayBuffer(); // Convert response to ArrayBuffer
      const blob = new Blob([responseData], {
        type: "image/jpg",
      }); // Create Blob object from ArrayBuffer
      const fileName =` ${orderNum }_${name}`|| "downloaded-file.jpg"; // Default filename
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error fetching the file:", error);
    }
  };
  const handleRevision = async (docId) => {
    try {
      const fileInput = document.createElement("input");
      fileInput.type = "file";
      fileInput.accept = ".jpg,.png,.pdf,.doc,.docx"; // Define accepted file types
      fileInput.click();
      fileInput.onchange = async (event) => {
        const file = event.target.files[0];
        if (file) {
          try {
            const formData = new FormData();
            formData.append("document", file);

            const response = await axiosPrivate.put(`docs/${docId}`, formData);

            if (response.status === 200) {
              showSuccessToast("Document revised successfully");
              fetchDocuments();
              // Update the state with the revised document
              //  setRevisedDocument(response.data?.data);
            }
          } catch (error) {
            console.error("Error revising document:", error);
          }
        }
      };
      // fileInput.click();
    } catch (error) {
      console.error("Error revising document:", error);
    }
  };

 

  const alreadyRevisedDocuments = revisedDocuments?.map(doc => doc.revision)

  return (
    <>
      {/* Existing Documents */}
      {documents && documents.length > 0 ? (
        <div
          className='content'
          style={{
            minHeight: `calc(100vh - 152px)`,
          }}
        >
          <div className='card'>
            <div className='card-header'>
              <h5>Sales Order No. {orderNum}</h5>
            </div>
            {/* Filter section */}
            <div className='filter-section form-group col-3 pl-4'>
              <Select
                options={prodOrderOptions}
                isMulti
                value={selectedProdOrders}
                onChange={setSelectedProdOrders}
                placeholder='--Select Production Order--'
              />
            </div>
            {/* Documents */}
            {revisedDocuments?.length ? (
              <h6 className='ml-3'>Revised Documents</h6>
            ) : null}

            <div className='card-body row p-3'>
              {revisedDocuments?.map((document, index) =>
                selectedProdOrders?.length === 0 ||
                selectedProdOrders?.some(
                  (selectedOrder) =>
                    selectedOrder.value === document.ProductionOrder
                ) ? (
                  <div
                    className='document-card col-md-2 col-sm-4'
                    key={index}
                  >
                    {/* Display document preview or icon */}
                    <div className='document-preview'>
                      {document.type === "image" ? (
                        <Link
                          to={
                            process.env.REACT_APP_MEDIA_URL + document?.document
                          }
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          <img
                            src={
                              process.env.REACT_APP_MEDIA_URL +
                              document?.document
                            }
                            alt={`Document ${index + 1}`}
                          />
                        </Link>
                      ) : (
                        <Link
                          to={
                            process.env.REACT_APP_MEDIA_URL + document?.document
                          }
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          <div className='file-icon'>
                            <FcFile />
                          </div>
                        </Link>
                      )}
                      {/* Show file name */}
                      <p className='file-name'>{`${orderNum}_${document.document}`}</p>
                    </div>
                    {/* Options on hover */}
                    <div className='options d-flex justify-content-center align-items-center mb-2'>
                      <FaDownload
                        className='download-icon'
                        onClick={() => handleDownload(document?.document)}
                      />
                      <FaTrash
                        className='delete-icon'
                        onClick={() => {
                          setSelectedDoc(document);
                          setDeleteConfirmationVisible(true);
                        }}
                      />
                      <RxUpdate
                        className='revise-icon'
                        onClick={() => handleRevision(document?.id)}
                      />
                    </div>
                  </div>
                ) : null
              )}
            </div>
            <h6 className='ml-3'>All Documents</h6>
            <div className='card-body row p-3'>
              {documents?.map((document, index) =>
                // Check if the document matches selected ProductionOrders
                selectedProdOrders.length === 0 ||
                selectedProdOrders.some(
                  (selectedOrder) =>
                    selectedOrder.value === document.ProductionOrder
                ) ? (
                  <div
                    className='document-card col-md-2 col-sm-4'
                    key={index}
                  >
                    {/* Display document preview or icon */}
                    <div className='document-preview'>
                      {document.type === "image" ? (
                        <Link
                          to={
                            process.env.REACT_APP_MEDIA_URL + document?.document
                          }
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          <img
                            src={
                              process.env.REACT_APP_MEDIA_URL +
                              document?.document
                            }
                            alt={`Document ${index + 1}`}
                          />
                        </Link>
                      ) : (
                        <Link
                          to={
                            process.env.REACT_APP_MEDIA_URL + document?.document
                          }
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          <div className='file-icon'>
                            <FcFile />
                          </div>
                        </Link>
                      )}
                      {/* Show file name */}
                      <p className='file-name'>{`${orderNum}_${document.document}`}</p>
                    </div>
                    {/* Options on hover */}
                    <div className='options d-flex justify-content-center align-items-center'>
                      <Link
                        to={
                          process.env.REACT_APP_MEDIA_URL + document?.document
                        }
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <FaDownload
                          className='download-icon'
                          onClick={() => handleDownload(document?.document)}
                        />
                      </Link>
                      <FaTrash
                        className='delete-icon'
                        onClick={() => {
                          setSelectedDoc(document);
                          setDeleteConfirmationVisible(true);
                        }}
                      />
                      {!alreadyRevisedDocuments.includes(document?.id) ? (
                        <RxUpdate
                          className='revise-icon'
                          onClick={() => handleRevision(document?.id)}
                        />
                      ) : null}
                    </div>
                  </div>
                ) : null
              )}
            </div>

            {/* Confirmation dialog for document deletion */}
            <Dialog
              visible={deleteConfirmationVisible}
              onHide={() => setDeleteConfirmationVisible(false)}
              header='Confirmation'
              footer={
                <>
                  <Button
                    label='Cancel'
                    className='btn btn-secondary mr-2'
                    onClick={() => setDeleteConfirmationVisible(false)}
                  />
                  <Button
                    label='Delete'
                    className='btn btn-primary'
                    onClick={handleDeleteDocument}
                  />
                </>
              }
            >
              <p>Are you sure you want to delete this document?</p>
            </Dialog>
          </div>
        </div>
      ) : (
        // Placeholder when no documents are available
        <div
          className='card text-center'
          style={{ height: "calc(100vh - 170px)" }}
        >
          <img
            src={Nodata}
            alt='No data found'
            style={{
              margin: "auto",
            }}
          />
        </div>
      )}
    </>
  );
};

export default UploadsViewPage;
