import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaEdit, FaTrash, FaUserAlt } from "react-icons/fa";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";
import axiosPrivate from "../../hooks/axiosPrivate";
import { usePermissions } from "../../context/permissions/PermissionsProvider";
import { useLoader } from "../../context/Loader/LoaderProvider"; // Import the useLoader hook
import Loader from "../../context/Loader/Loader"; // Import the Loader component
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { GoDotFill } from "react-icons/go";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

const UsersList = () => {
  // State for storing user data
  const [usersData, setUsersData] = useState([]);
  const navigate = useNavigate();
  const { isLoading, setIsLoading } = useLoader(); // Get the isLoading state and setIsLoading function from LoaderProvider
  const { authPermissions } = usePermissions();
  const [userId, setUserId] = useState(null);
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false); 
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

 
  useEffect(() => {
    if (userId) {
      setDeleteConfirmationVisible(true);
    }
  }, [userId]);  
  const userDeleteHandler = async (id) => {
    try {
      const response = await axiosPrivate.delete(`users/${id}`);
      if (response.status === 200) {
        showSuccessToast("User deleted successfully");
        fetchData();

      }
    } catch (error) {
      showErrorToast(error.message);
    } finally {
      setUserId(null)
    }
  };
  // Function to fetch user data
  const fetchData = async () => {
    try {
      setIsLoading(true); // Set isLoading to true before fetching data
      const response = await axiosPrivate.get("users");
      if (response.status === 200) {
        setUsersData(response.data?.data);
      }
    } catch (error) {
      if (error.response?.status === 403) {
        navigate("/forbidden");
      }
      else if (error.response?.status !== 404) {
        showErrorToast(error.message);
      }
    } finally {
      setIsLoading(false); // Set isLoading to false after fetching data (whether success or error)
    }
  };

  useEffect(() => {
    fetchData(); // Fetch data on component mount
  }, []);

  // Define table headers
  const headers = [
    { name: "Name", field: "name", sortable: true, classKey: "" },
    { name: "Employee Code", field: "emp_code", sortable: true, classKey: "" },
    { name: "Role", field: "role_name", sortable: true, classKey: "" },
    { name: "Email", field: "email", sortable: true, classKey: "" },
    { name: "Mobile", field: "mobile", sortable: true, classKey: "" },
    { name: "Last Login", field: "last_login", sortable: true, classKey: "" },
    // { name: "Status", field: "status", sortable: true, classKey: "" },
    { name: "Action", field: "action", classKey: "" },
  ];

  // Prepare data for rendering in DataTable
  const usersListData = usersData?.map((user, index) => {
    let buttons = [];

    // Add Edit button if the user has permission
    if (authPermissions?.includes("Users-Update")) {
      buttons.push(
        <Link
          key={`editButton_${user.id}`}
          to={`/users/edit/${user.id}`}
          style={{ border: "none", background: "none", padding: "6px" }}
          title='Edit'
        >
          <FaEdit
            color='green'
            size={13}
          />
        </Link>
      );
    }

    // Add Permission button
    if (authPermissions?.includes("Users-Update")) {
      buttons.push(
        <Link
          key={`userButton_${user.id}`}
          to={`/users/permissions/${user.id}`}
          style={{ border: "none", background: "none", padding: "6px" }}
          title='Permission'
        >
          <FaUserAlt
            color='#232323'
            size={13}
          />
        </Link>
      );
    }

    
    // Assign action buttons to the user data
    user["action"] = buttons.length > 0 ? buttons : "-";

    return { ...user, index: index + 1 };
  });

  const onGlobalFilterChange = (event) => {
    const { value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      global: { value: value, matchMode: FilterMatchMode.CONTAINS },
    }));
  };

  const renderHeader = () => {
    const value = filters["global"] ? filters["global"].value : "";

    return (
      <div className='d-flex justify-content-between w-100'>
        {/* Render user management section if user has permission */}
        {authPermissions?.includes("Users-Create") && (
          <Link
            to='add'
            className='text-decoration-none'
          >
            <button
              type='button'
              className='btn btn-primary'
              data-toggle='modal'
              data-target='#exampleModal'
            >
              <i className='fe fe-plus mr-2' />
              Add
            </button>
          </Link>
        )}
        <InputText
          type='search'
          value={value || ""}
          onChange={onGlobalFilterChange}
          placeholder='Global Search'
        />
      </div>
    );
  };

  const header = renderHeader();

  const statusBodyTemplate = (rowData) => {
    return rowData?.checked_in === 1 ? (
      <GoDotFill
        size={20}
        color='#73dc45'
        title='Active'
      />
    ) : (
      <GoDotFill
        size={20}
        color='#ff1d1d'
        title='Inactive'
      />
    );
  };

  const deleteBodyTemplate = (rowData) => {
    return (
      <button
        type='button'
        onClick={() => {
          setUserId(rowData.id);
        }}
        title='Delete'
        style={{ border: "none", background: "none", padding: "6px", textAlign: "left" }}
      >
        <FaTrash
          color='red'
          size={13}

        />
      </button>
    );
  }

  return (
    <>
      {/* Conditionally render Loader component based on isLoading state */}
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className='content'>
            <div className='page-header'>
              <h4 className='m-1 pl-3'>Users</h4>
            </div>
            {/* Render user list section */}
            <div className='card'>
              <div className='card-body p-0 '>
                <div className='table-responsive'>
                  {/* Render DataTable component */}
                  <DataTable
                    value={usersListData}
                    dataKey='id'
                    header={header}
                    filters={filters}
                    onFilter={(e) => setFilters(e.filters)}
                    paginator
                    rows={10}
                    rowsPerPageOptions={[10, 50, 100, 150]}
                    paginatorTemplate='RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink'
                    currentPageReportTemplate='{first} to {last} of {totalRecords}'
                  >
                    <Column
                      field='status'
                      header='Status'
                      body={statusBodyTemplate}
                      sortable={false}
                      bodyStyle={{ textAlign: "left", paddingLeft: "1%" }}
                    />
                    <Column
                      field='index'
                      header='Sr. No.'
                      headerStyle={{ width: "5%" }}
                      sortable
                      bodyStyle={{ textAlign: "left"}}
                    />
                    {headers.map((header, index) => {
                      return (
                        <Column
                          key={index}
                          field={header?.field}
                          body={
                            header?.field === "status"
                              ? statusBodyTemplate
                              : false
                          }
                          header={header?.name}
                          sortable={header?.field !== "action"}
                          bodyStyle={{
                            textAlign:
                              header?.field === "status"
                                ? "center"
                                : header?.field === "action"
                                ? "right"
                                : "left",
                          }}
                          headerStyle={{
                            width: header?.field === "action" ? "10%" : "",
                            paddingLeft:
                              header?.field === "action" ? "110px" : "",
                          }}
                        />
                      );
                    })}
                    <Column
                      field='delete_handler'
                      body={deleteBodyTemplate}
                      headerStyle={{ width: "15%" }}
                      bodyStyle={{ textAlign: "left" }}
                    />
                  </DataTable>
                </div>
              </div>
            </div>
            <Dialog
              visible={deleteConfirmationVisible}
              onHide={() => {
                setDeleteConfirmationVisible(false);
                setUserId(null);
              }}
              header='Confirmation'
              footer={
                <>
                  <Button
                    label='Cancel'
                    className='p-button-text mr-2'
                    onClick={() => {
                      setUserId(null);
                      setDeleteConfirmationVisible(false);
                    }}
                  />
                  <Button
                    label='Delete'
                    className='p-button-danger'
                    onClick={() => {
                      userDeleteHandler(userId);
                      setDeleteConfirmationVisible(false);
                    }}
                  />
                </>
              }
            >
              <p>Are you sure you want to delete this user!</p>
            </Dialog>
          </div>
        </>
      )}
    </>
  );
};

export default UsersList;
