import React, { useEffect, useState } from "react";
import axiosPrivate from "../../hooks/axiosPrivate";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";
import Loader from "../../context/Loader/Loader"; // Import the Loader component
import { useLoader } from "../../context/Loader/LoaderProvider"; // Import the useLoader hook
import { Link } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { Dialog } from "primereact/dialog";
import { useFormik } from "formik";
import * as Yup from "yup";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { usePermissions } from "../../context/permissions/PermissionsProvider";
import { Column } from "primereact/column";

const RequestsList = () => {
  const { isLoading, setIsLoading } = useLoader(); // Get the isLoading state and setIsLoading function from LoaderProvider
  const [requests, setRequests] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [displayDialog, setDisplayDialog] = useState(false);
  const { authPermissions } = usePermissions();
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const validationSchema = Yup.object().shape({
    password: Yup.string().required("New Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        // Call API to update password
        const response = await axiosPrivate.put(
          `reset-password/${selectedUserId}`,
          {
            password: values.password,
          }
        );
        if (response.status === 200) {
          // Close dialog and refresh data
          setDisplayDialog(false);
          fetchData();
          formik.resetForm();
         
          showSuccessToast("Password updated successfully");
        }
      } catch (error) {
        if (error?.response?.status !== 404) {
          showErrorToast(error?.response?.data?.message);
        }
      }
    },
  });

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const fetchData = async () => {
    try {
      setIsLoading(true); // Set isLoading to true before fetching data
      const response = await axiosPrivate.get("request-password");
      if (response.status === 200) {
        // Format the created_at date into dd/mm/yyyy format
        const formattedData = response?.data?.data.map((item) => ({
          ...item,
          created_at: formatDate(item.created_at),
        }));
        setRequests(formattedData);
      }
    } catch (error) {
      if (error?.response?.status !== 404) {
        showErrorToast(error.message);
      }
    } finally {
      setIsLoading(false); // Set isLoading to false after fetching data (whether success or error)
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Define table headers
  const headers = [
    { field: "user_empcode", name: "Employee Code", sortable: true },
    { field: "user_name", name: "Username", sortable: true },
    { field: "created_at", name: "Request Date", sortable: true },
    { name: "Action", field: "action", classKey: "" },
  ];



  const handleEditClick = (userId) => {
    setSelectedUserId(userId);
    setDisplayDialog(true);
  };

  const handleCloseDialog = () => {
    setDisplayDialog(false);
    setSelectedUserId(null);
    formik.resetForm();
  };

  const generateRandomPassword = () => {
    // Function to generate a random password
    const characters =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let password = "";
    for (let i = 0; i < 10; i++) {
      password += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    formik.setFieldValue("password", password); // Set the generated password to the formik field
  };

  const requestListData = requests?.map((user) => {
    let buttons = [];

    // Add Edit button if the user has permission
    buttons.push(
      <button
        key={`editButton_${user.id}`}
        onClick={() => handleEditClick(user?.user_id)}
        style={{ border: "none", background: "none", marginRight: "10px" }}
        title='Edit'
      >
        <FaEdit
          color='green'
          size={13}
        />
      </button>
    );

    // Assign action buttons to the user data
    user["action"] = buttons.length > 0 ? buttons : "-";

    return user;
  });

  const onGlobalFilterChange = (event) => {
    const { value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      global: { value: value, matchMode: FilterMatchMode.CONTAINS },
    }));
  };

  const renderHeader = () => {
    const value = filters["global"] ? filters["global"].value : "";

    return (
      <div className='d-flex justify-content-end w-100'>
        <InputText
          type='search'
          value={value || ""}
          onChange={onGlobalFilterChange}
          placeholder='Global Search'
        />
      </div>
    );
  };

  const header = renderHeader();

  return (
    <div>
      {/* Conditionally render Loader component based on isLoading state */}
      {isLoading ? (
        <Loader />
      ) : (
        <>
         <div className='page-header'>
            <h4 className='m-1 pl-3'>Credential Requests</h4>
          </div>
          <div className='card'>
            <div className='card-body p-0'>
              <DataTable
                value={requestListData}
                header={header}
                dataKey='id'
              >
                {headers.map((header, index) => (
                  <Column
                    key={index}
                    field={header?.field}
                    header={header?.name}
                    sortable={header?.field !== "action"}
                  />
                ))}
              </DataTable>
            </div>
          </div>
        </>
      )}

      {/* Dialog for editing password */}
      <Dialog
        header='Edit Password'
        visible={displayDialog}
        style={{ width: "30vw" }}
        onHide={handleCloseDialog}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className='col-md-12 col-sm-12'>
            <div className='form-group'>
              <label htmlFor='password'>New Password</label>
              <input
                id='password'
                name='password'
                type='text'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                className='form-control'
              />
              {formik.touched.password && formik.errors.password && (
                <small className='p-error'>{formik.errors.password}</small>
              )}
            </div>
          </div>

          <div className='d-flex justify-content-end'>
            <button
              type='button'
              className='btn btn-primary mr-2'
              onClick={generateRandomPassword} // Call the function to generate a random password
            >
              Generate Password
            </button>
            <button
              type='submit'
              className='btn btn-primary'
            >
              Update
            </button>
          </div>
        </form>
      </Dialog>
    </div>
  );
};

export default RequestsList;
