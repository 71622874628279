import React from "react";
import ReactFlow, { Background, MiniMap, Controls } from "reactflow";
import "reactflow/dist/style.css";

const StageDependencyGraph = ({ stages }) => {
  // Create node elements for each stage
  const elements = stages.map((stage, index) => ({
    id: stage.id,
    data: { label: stage.name },
    position: {
      x: stage.parent ? (index + 1) * 150 : (index + 1) * 350, // Adjust x position
      y: stage.parent ? (index + 1) * 100 : 0, // Adjust y position
    },
  }));

  // Create edge elements for dependencies between stages
  const edgeElements = stages
    .filter((stage) => stage.parent)
    .map((stage) => ({
      id: `${stage.id}-${stage.parent}`,
      source: stage.parent,
      target: stage.id,
      animated: true,
    }));

  return (
    <div style={{ height: "600px", width: "100%", border: "1px solid #ddd" }}>
      {/* ReactFlow component */}
      <ReactFlow
        nodes={elements} // Node elements
        edges={edgeElements} // Edge elements
        hideAttribution = {true}
        style={{ height: "100%", width: "100%" }} // Adjust style
      >
        {/* Controls for zooming and panning */}
        <Controls />
        {/* MiniMap for overview */}
        <MiniMap />
        {/* Background style */}
        <Background
          variant='dots'
          style={{ background: "#f4f4f4" }}
          gap={12}
          size={1}
        />
      </ReactFlow>
    </div>
  );
};

export default StageDependencyGraph;
