import React, { useEffect, useState } from "react";
import { showErrorToast } from "../../utils/Toaster";
import axiosPrivate from "../../hooks/axiosPrivate";
import { Chart } from "primereact/chart";

/**
 * SupervisorLoadGraph is a component that displays the load on
 * supervisors in a bar chart.
 *
 * It fetches data from the backend, sets the data and options for the chart,
 * and renders a chart component with the data and options.
 */
const SupervisorLoadGraph = () => {
  const userData = JSON.parse(localStorage.getItem("userData") || "{}");
  


  // State variables to store chart data and options
  const [chartData, setChartData] = useState([]);
  const [chartOptions, setChartOptions] = useState({});
  const [supervisorLoadData, setSupervisorLoadData] = useState({});
 
 
 

 const [selectedPlant, setSelectedPlant] = useState(() => {
   if (
     userData &&
     userData.company_and_plants &&
     userData.company_and_plants.length > 0
   ) {
     return userData?.company_and_plants[0].plant_id;
   }
   return "";
 });

  /**
   * fetchChartData is an asynchronous function that fetches supervisor load
   * data from the backend and updates the chartData state variable.
   *
   * It uses the axiosPrivate hook to make a GET request to the
   * "dashboard/supervisor_load" endpoint. It handles errors and displays a
   * toast message if the request fails.
   */
  const fetchChartData = async () => {
    try {
      const response = await axiosPrivate.get(
        `dashboard/supervisor_load?plant_id=${selectedPlant}`
      );
      if (response.status === 200) {
        setChartData(response?.data?.data);
      }
    } catch (error) {
      if (error?.response?.status !== 404) {
        showErrorToast(error.message);
      } else if (error?.response?.status === 404) {
        setChartData([]);
      }
    }
  };

  /**
   * useEffect is a hook that runs the fetchChartData function when the
   * component is mounted.
   */
  useEffect(() => {
    fetchChartData();
  }, [selectedPlant]);

  /**
   * useEffect is a hook that runs the setSupervisorLoadData and setChartOptions
   * functions whenever the chartData state variable changes.
   *
   * It sets the supervisorLoadData state variable with a new object containing
   * the labels and datasets for the chart. It sets the chartOptions state
   * variable with a new object containing the scales for the chart.
   */
  useEffect(() => {
    const data = {
      labels: chartData?.map((supervisor) => supervisor.name),
      datasets: [
        {
          label: "In Progress Jobs",
          backgroundColor: "rgb(2, 136, 209)",
          borderColor: "rgb(2, 136, 209,0.4)",
          data: chartData?.map((supervisorData) => supervisorData?.in_progress),
        },
        {
          label: "Pending Jobs",
          backgroundColor: "rgb(251, 192, 45)",
          borderColor: "rgb(251, 192, 45)",
          data: chartData?.map((supervisorData) => supervisorData?.pending),
        },
      ],
    };

    const options = {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    };

    setSupervisorLoadData(data);
    setChartOptions(options);

  }, [chartData]);



  return (
    <div className='card'>
      <div className='card-header'>
        <h3 className='card-title justify-content-center'>
          <strong>Load On Supervisors</strong>
        </h3>
      </div>
      <div className='card-body'>
        {Number(userData?.role_id) === 3 ? (
          <div className='col-xl-12 col-lg-12 col-md-12'>
            <div className='dropdown w-100'>
              <select
                className='btn dropdown-toggle'
                style={{background: '#ebebeb'}}
                onChange={(e) => setSelectedPlant(e.target.value)}
                value={selectedPlant}
              >
                {userData?.company_and_plants?.map((plant) => (
                  <option
                    value={plant?.plant_id}
                    key={plant?.plant_id}
                  >
                    {plant?.plant_name}
                  </option>
                ))}
              </select>
              {/* Calendar inputs for custom range */}
            </div>
          </div>
        ) : null}
        {chartData?.length > 0 ? (
          <Chart
            type='bar'
            style={{ padding: "0% 8%" }}
            data={supervisorLoadData}
            options={chartOptions}
            className='w-full'
          />
        ) : (
          <div className='d-flex justify-content-center align-items-center h-100 mb-4'>
            No Supervisors Available!
          </div>
        )}
      </div>
    </div>
  );
};

export default SupervisorLoadGraph;
