import React, { useState, useEffect } from "react";
import axiosPrivate from "../../hooks/axiosPrivate";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";
import Loader from "../../context/Loader/Loader";
import { FaEdit, FaTrash } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useLoader } from "../../context/Loader/LoaderProvider";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { usePermissions } from "../../context/permissions/PermissionsProvider";
// import { Default } from "react-toastify/dist/utils";

const headers = [
  { field: "product_name", header: "Stage Template", sortable: true },
  {
    field: "product_category_name",
    header: "Product Category",
    sortable: true,
  },

  { field: "plant_name", header: "Plant Name", sortable: true },
  // { field: "default_product", header: "Default Product", sortable: true },
  { field: "action", header: "ACTION", sortable: false },
];

const Product = () => {
  const [productsData, setProductsData] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const { authPermissions } = usePermissions();
  const [showDialog, setShowDialog] = useState(false);
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);
  const [productId, setProductId] = useState(null);
  const { isLoading, setIsLoading } = useLoader();
  const [filters, setFilters] = useState({
    global: { value: "", matchMode: FilterMatchMode.CONTAINS },
  });

/**
 * Fetches the products data from the backend and updates the state with the response.
 * This function is called when the component mounts to initialize the products data.
 *
 * @async
 * @function fetchData
 * @returns {Promise<void>} - Resolves when the data has been fetched and the state has been updated.
 */
const fetchData = async () => {
  try {
    // Set isLoading to true before fetching data
    setIsLoading(true);

    // Fetch the products data from the backend
    const response = await axiosPrivate.get("product");

    // If the status is successful, update the products data
    if (response.status === 200) {
      setProductsData(response.data?.data);
    }
  } catch (error) {
    // If there is an error, show an error toast
    showErrorToast(error.message);
  } finally {
    // Set isLoading to false after fetching data (whether success or error)
    setIsLoading(false);
  }
};


  useEffect(() => {
    fetchData();
  }, []);

  // const productDeleteHandler = async (id) => {
  //   try {
  //     const response = await axiosPrivate.delete(`product/${id}`);
  //     if (response.status === 200) {
  //       showSuccessToast("Product deleted successfully");
  //       fetchData();
  //     }
  //   } catch (error) {
  //     showErrorToast(error.message);
  //   }
  // };
  useEffect(() => {
    if (productId) {
      setDeleteConfirmationVisible(true);
    }
  }, [productId]);  
  const productDeleteHandler = async (id) => {
    try {
      const response = await axiosPrivate.delete(`product/${id}`);
      if (response.status === 200) {
        showSuccessToast("Stage template deleted successfully");
        fetchData();

      }
    } catch (error) {
      
      showErrorToast(error.response?.data?.errors[0]?.msg);
    } finally {
      setProductId(null)
    }
  };
  const onGlobalFilterChange = (event) => {
    const { value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      global: { value: value, matchMode: FilterMatchMode.CONTAINS },
    }));
  };

  const makeDefaultHandler = async () => {
    if (!selectedProduct) {
      showErrorToast("Please select a stage template to set as default");
      return;
    }
    setShowDialog(true);
  };

  const setAsDefaultProduct = async () => {
    // Make API call to set the selected product as default
    try {
      const response = await axiosPrivate.put(
        `product/default/${selectedProduct.id}`
      );
      if (response.status === 200) {
        showSuccessToast("Stage template set as default successfully");
        fetchData();
        setSelectedProduct(null); // Reset selected product
      }
    } catch (error) {
      showErrorToast(error.message);
    }
    setShowDialog(false);
  };

  const onHideDialog = () => {
    setShowDialog(false);
    setSelectedProduct(null);
  };

  /**
   * Renders the header for the Product component.
   *
   * The header includes:
   * - A button to add a new product (linked to /add)
   * - A button to set the currently selected product as the default (disabled if no product is selected)
   * - A global search input
   *
   * @returns {JSX.Element}
   */
  const renderHeader = () => {
    // Get the global filter value from the filters state.
    // If it doesn't exist, set it to an empty string.
    const value = filters["global"] ? filters["global"].value : "";

    return (
      // Render a div with a flex layout.
      // The first child is a div with two buttons.
      // The second child is an input text field.
      <div className='d-flex justify-content-between w-100'>
        <div>
          {/* Render a link to /add for adding a new product. */}
          {/* The link is inside a button with a text decoration of none. */}
          <Link
            to='add'
            className='text-decoration-none'
          >
            <button
              type='button'
              className='btn btn-primary'
              data-toggle='modal'
              data-target='#exampleModal'
            >
              {/* Render the plus icon and the word "Add" inside the button. */}
              <i className='fe fe-plus mr-2' />
              Add
            </button>
          </Link>
          {/*
          Render a button to set the currently selected product as the default.
          The button is disabled if no product is selected.
          */}
          <button
            className='btn btn-primary ml-2'
            onClick={makeDefaultHandler}
          >
            Make Default
          </button>
        </div>
        {/*
        Render a global search input.
        The input field is of type search.
        The value is the global filter value.
        The onChange function is onGlobalFilterChange.
        The placeholder is "Global Search".
        */}
        <InputText
          type='search'
          value={value || ""}
          onChange={onGlobalFilterChange}
          placeholder='Global Search'
        />
      </div>
    );
  };


  const header = renderHeader();

  /**
   * Maps the `productsData` array to an array of objects with additional properties.
   * Each object in the resulting array represents a row in a data table.
   * The additional properties include:
   * - `index`: the index of the row, starting from 1
   * - `action`: an array of React elements representing action buttons for the row (e.g. edit button)
   *
   * @param {Object[]} productsData - An array of product data objects.
   * @returns {Object[]} - An array of objects representing the rows in the data table.
   */
  const productsListData = productsData?.map((value, index) => {
    let buttons = [];

    buttons.push(
      <Link
        key={`editButton_${value.id}`}
        to={`/products/edit/${value.id}`}
        type='button'
        style={{ border: "none", background: "none", padding: "6px" }}
        title='Edit'
      >
        <FaEdit
          color='green'
          size={13}
        />
      </Link>
    );

    return { ...value, index: index + 1, action: buttons };
  });

  const nameBodyTemplate = (rowData) => {
    return rowData.is_default ? (
      <span style={{ fontWeight: "bold" }}>
        {rowData.product_name} <i style={{ color: "#007bff" }}>(Default)</i>
      </span>
    ) : (
      rowData?.product_name
    );
  };

  const deleteBodyTemplate = (rowData) => {
    return (
      <button
        type='button'
        onClick={() => {
          setProductId(rowData.id);
        }}
        title='Delete'
        style={{ border: "none", background: "none", padding: "6px", textAlign: "left" }}
      >
        <FaTrash
          color='red'
          size={13}

        />
      </button>
    );
  }

  return (
    <>
      <div className='content'>
        <div className='page-header'>
          <h4 className='m-1 pl-3'>Stage Templates</h4>
        </div>
        <div className='card'>
          <div className='card-body p-0'>
            <div className='table-responsive'>
              {isLoading ? (
                <Loader />
              ) : (
                <>
                  <DataTable
                    value={productsListData}
                    paginator
                    rows={10}
                    rowsPerPageOptions={[10, 50, 100, 150]}
                    paginatorTemplate='RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink'
                    currentPageReportTemplate='{first} to {last} of {totalRecords}'
                    header={header}
                    filters={filters}
                    onFilter={(e) => setFilters(e.filters)}
                    emptyMessage='No records found'
                    selectionMode='checkbox'
                    selection={selectedProduct}
                    onSelectionChange={(e) => setSelectedProduct(e.value)}
                    dataKey='id'
                  >
                    <Column
                      selectionMode='single'
                      headerStyle={{ width: "2%" }}
                    />
                    <Column
                      field='index'
                      header='Sr. No.'
                      headerStyle={{ width: "15%" }}
                      sortable
                      bodyStyle={{ textAlign: "left" }}
                    />
                    {headers.map((header) => (
                      <Column
                        key={header.field}
                        field={header.field}
                        body={
                          header.field === "product_name"
                            ? nameBodyTemplate
                            : ""
                        }
                        header={header.header}
                        sortable={header.sortable}
                        bodyStyle={{
                          textAlign:
                            header?.field === "action" ? "right" : "left",
                        }}
                        headerStyle={{
                          width: header?.field === "action" ? "8%" : "",
                          paddingLeft:
                            header?.field === "action" ? "115px" : "",
                        }}
                      />
                    ))}
                    {authPermissions.includes("Stage Templates-Delete") && (
                      <Column
                        field='delete_handler'
                        body={deleteBodyTemplate}
                        headerStyle={{ width: "10%" }}
                        bodyStyle={{ textAlign: "left" }}
                      />
                    )}
                  </DataTable>
                  <Dialog
                    header='Confirmation'
                    visible={showDialog}
                    style={{ width: "30vw" }}
                    onHide={onHideDialog}
                  >
                    <div className='p-d-flex p-ai-center p-dir-col'>
                      <p>
                        Are you sure you want to set this product as default?
                      </p>
                      <div className='p-d-flex p-ai-center'>
                        <button
                          className='p-button p-button-primary'
                          onClick={setAsDefaultProduct}
                        >
                          Yes
                        </button>
                        <button
                          className='p-button p-button-secondary ml-3'
                          onClick={onHideDialog}
                        >
                          No
                        </button>
                      </div>
                    </div>
                  </Dialog>
                  <Dialog
                    visible={deleteConfirmationVisible}
                    onHide={() => {
                      setDeleteConfirmationVisible(false);
                      setProductId(null);
                    }}
                    header='Confirmation'
                    footer={
                      <>
                        <Button
                          label='Cancel'
                          className='p-button-text mr-2'
                          onClick={() => {
                            setProductId(null);
                            setDeleteConfirmationVisible(false);
                          }}
                        />
                        <Button
                          label='Delete'
                          className='p-button-danger'
                          onClick={() => {
                            productDeleteHandler(productId);
                            setDeleteConfirmationVisible(false);
                          }}
                        />
                      </>
                    }
                  >
                    <p>Are you sure you want to delete this Stage Template?</p>
                  </Dialog>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Product;
