import React from "react";
import "./Jobcard.css"; // Import CSS file for styling
import { LuLayoutTemplate } from "react-icons/lu";
import { PiFactory, PiUsersThree } from "react-icons/pi";

const JobCard = ({ data, isSuperAdmin }) => {
  const jobs = isSuperAdmin
    ? [
        {
          Job_type: "Users",
          job_number: data ? data.all_users : 0,
          icon: <PiUsersThree />,
        },
        {
          Job_type: "Stage Templates",
          job_number: data ? data.products : 0,
          icon: <LuLayoutTemplate />,
        },
        {
          Job_type: "Plants",
          job_number: data ? data.plants : 0,
          icon: <PiFactory />,
        },
        {
          Job_type: "Active Users",
          job_number: data ? data.all_active_users : 0,
          icon: <PiUsersThree />,
        },
      ]
    : [
        {
          Job_type: "Assigned Jobs",
          job_number: data ? data.length : 0,
          icon: "",
        },
        {
          Job_type: "Lines On Hold",
          job_number: data
            ? data?.filter((stage) => stage.status === 2)?.length
            : 0,
          icon: "",
        },
        {
          Job_type: "Overdue Jobs",
          job_number: data
            ? data.filter((stage) =>
                typeof stage?.flags === "string"
                  ? JSON.parse(stage?.flags)?.includes("Overdue")
                  : stage?.flags?.includes("Overdue")
              )?.length
            : 0,
          icon: "",
        },
        {
          Job_type: "Today's Due Jobs",
          job_number: data
            ? data.filter(
                (stage) =>
                  new Date(stage.planned_end).setHours(0, 0, 0, 0) ===
                  new Date().setHours(0, 0, 0, 0)
              )?.length
            : 0,
          icon: "",
        },
      ];

  return (
    <div className='job-card-container'>
      <div className='row justify-content-between w-100'>
        {jobs.map((job, index) => (
          <div
            className='job-card col'
            key={index}
          >
            {job.icon !== "" ? <div className='icon-container'>{job.icon}</div> : null }
            <p className='job-number'>{job.job_number}</p>
            <p className='job-info'>
              <strong>{job.Job_type}</strong>
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default JobCard;
