import React from "react";
import styles from "./Forbidden.module.css";
import { Link } from "react-router-dom";

const Forbidden = () => {
  return (
    <div className={styles.forbidden}>
      <div className={styles.lock}></div>
      <div
        className={styles.message}
        style={{ marginTop: "30px" }}
      >
        <h2>Oops! You are not autorized to access this page.</h2>
        <p className='text-center '>
          Please check with the site admin if you believe this is a mistake.
        </p>
      </div>
    </div>
  );
};

export default Forbidden;
