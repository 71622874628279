import React, { useEffect } from "react";
import TopNavBar from "../../Layouts/TopNavBar";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosPrivate from "../../hooks/axiosPrivate";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";
import { useNavigate, useParams } from "react-router-dom";

const AddEditCompany = () => {
  const { id } = useParams(); // Get the id from the URL params
  const navigate = useNavigate(); // Get the navigate function from the router

  // Fetch company data if id is provided
  useEffect(() => {
    /**
     * Retrieves the company data from the API.
     *
     * @returns {Promise<void>}
     */
    const getCompany = async () => {
      try {
        // If an id is provided, retrieve the company data
        if (id) {
          // Make a GET request to the API with the company id
          const res = await axiosPrivate.get(`company/${id}`);

          // Set the company form values to the data retrieved
          setValues(res.data.data);
        }
      } catch (err) {
        // Display an error message
        showErrorToast(err.message);
      }
    };

    if (id) {
      getCompany();
    }
  }, [id]);

  // Define the validation schema for the company form
  const companySchema = Yup.object({
    name: Yup.string().required("Name is Required"),
    code: Yup.string().required("Code is Required"),
    // description: Yup.string().required("Description is Required"),
  });
  const companyForm = {
    name: "",
    description: "",
    code: "",
  };

  // Initialize formik for form management


  /**
   * Initializes and manages the state and behavior of a company form using the Formik library.
   * The returned object contains various properties and methods that can be used to interact with the form.
   *
   * @returns {Object} An object containing the following properties and methods:
   *   - values: The current values of the form fields.
   *   - handleBlur: A function to handle the onBlur event of form fields.
   *   - handleChange: A function to handle the onChange event of form fields.
   *   - handleSubmit: A function to handle the form submission.
   *   - errors: An object containing validation errors for the form fields.
   *   - touched: An object indicating which form fields have been touched (i.e., focused and then blurred).
   *   - setValues: A function to set the values of the form fields.
   *   - setFieldValue: A function to set the value of a specific form field.
   */
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: companyForm,
    validationSchema: companySchema,
    onSubmit: async (values) => {
      try {
        if (id) {
          await axiosPrivate.put(`company/${id}`, values);
          navigate("/company");
          showSuccessToast("Company updated successfully");
        } else {
          await axiosPrivate.post(`company`, values);
          navigate("/company");
          showSuccessToast("Company added successfully");
        }
      } catch (err) {
        showErrorToast(err.message);
      }
    },
  });

  // Render error message for a given field
  const renderError = (fieldName) => {
    return touched[fieldName] && errors[fieldName] ? (
      <div style={{ color: "red", fontSize: "12px" }}>{errors[fieldName]}</div>
    ) : null;
  };

  // Render the company form
  return (
    <div className='content'>
      <div
        className='section-body d-flex justify-content-center align-items-center'
        style={{ minHeight: `calc(100vh - 106px)` }}
      >
        <div
          className='card  '
          style={{ width: "30%" }}
        >
          <form onSubmit={handleSubmit}>
            <div className='card-header'>
              <strong>Add Company</strong>
            </div>
            <div className='card-body'>
              <div className='row clearfix'>
                <div className='col-12'>
                  <div className='form-group'>
                    <label className='form-label'>Company Name</label>
                    <input
                      id='name'
                      name='name'
                      type='text'
                      className='form-control'
                      placeholder='Company-Name *'
                      onChange={handleChange}
                      onBlur={(e) => {
                        handleBlur(e);
                        setFieldValue("name", values.name.trim());
                      }}
                      value={values.name}
                    />
                  </div>
                  {renderError("name")}
                </div>
                <div className='col-12'>
                  <div className='form-group'>
                    <label className='form-label'>Company Code</label>
                    <input
                      id='code'
                      name='code'
                      type='text'
                      className='form-control'
                      placeholder='Company-Code *'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.code}
                    />
                  </div>
                  {renderError("code")}
                </div>
                <div className='col-12'>
                  <div className='form-group'>
                    <label className='form-label'>Description</label>
                    <textarea
                      name='description'
                      id='description'
                      placeholder='Company-Description'
                      rows={3}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.description}
                    ></textarea>
                  </div>
                  {renderError("description")}
                </div>
                <div className='col-12 text-right'>
                  <hr className='mt-4' />

                  <button
                    type='button'
                    id='button_1'
                    className='btn btn-secondary mx-1 '
                    onClick={() => navigate("/company")}
                  >
                    CLOSE
                  </button>
                  <button
                    type='submit'
                    id='button_2'
                    className='btn btn-primary'
                  >
                    SUBMIT
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddEditCompany;
