import React, { useState, useEffect, useMemo, useCallback } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Dropdown } from "primereact/dropdown";
import axiosPrivate from "../../hooks/axiosPrivate";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";
import { useFormik } from "formik";
import { Dialog } from "primereact/dialog";
import Select from "react-select";
import { Tooltip } from "primereact/tooltip";
import { FaCircleInfo } from "react-icons/fa6";
import { Tag } from "primereact/tag";
import * as Yup from "yup";
import { InputSwitch } from "primereact/inputswitch";
import { GrFormView } from "react-icons/gr";
import { Link } from "react-router-dom";
import { Calendar } from "primereact/calendar";
import { MdOutlineDoneOutline, MdOutlinePending } from "react-icons/md";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { InputTextarea } from "primereact/inputtextarea";
import {
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaAngleLeft,
  FaAngleRight,
} from "react-icons/fa";
// Headers for stages DataTableLines component
const DataTableLines = ({
  lists,
  updateJobData,
  paginator,
  updateLinesData,
  supervisors,
  setPageNo,
  setOffset,
  pageNo,
  offset,
  totalJobs,
  totalLines,
  supervisorId,
  setSupervisorId,
  productionOrder,
  setProductionOrder,
  runningStages,
  setRunningStages,
  releaseDate,
  setReleaseDate,
  lineStatus,
  setLineStatus,
  isListingPage,
  fetchLinesData,
  linesPagination
}) => {
  // State variables

 
  const [newList, setNewList] = useState([]);
  const [showpaginator, setshowPaginator] = useState(false);
  const [selectedRows, setSelectedRows] = useState(null);
  const [fileNames, setFileNames] = useState([]);
  const [visible, setVisible] = useState(false);
  const [runningStagesDialog, setRunningStagesDialog] = useState(false);
  const [updateStages, setUpdateStages] = useState(false);
  const [checked, setChecked] = useState(false);
  // const [productionOrder, setProductionOrder] = useState(null);
  // const [releaseDate, setReleaseDate] = useState(null);
  // const [supervisorId, setSupervisorId] = useState(null);
  const [updateSupervisorDialog, setUpdateSupervisorDialog] = useState(false);
  const [lineStatusDialog, setLineStatusDialog] = useState(false);
  const [releaseDateDialog, setReleaseDateDialog] = useState(false);
  const [lineRemark, setLineRemark] = useState("");
  const [bulkReleaseDialog, setBulkReleaseDialog] = useState(false);
  const [bulkRelaseDate, setBulkReleaseDate] = useState("");
  // const [runningStages, setRunningStages] = useState(null);
  // const [lineStatus, setLineStatus] = useState(null);
   const [first, setFirst] = useState(0);
   const [currentPage, setCurrentPage] = useState(pageNo);
   const [pageInput, setPageInput] = useState(pageNo);

  const userData = JSON.parse(localStorage?.getItem("userData")) || {};
  const uploadForm = {
    // description: "",
    prod_orders_upload: [],
    documents: [],
  };

  // Define validation schema using Yup
  const uploadSchema = Yup.object({
    prod_orders_upload: Yup.array().min(1, "Production Order is required!"),
    documents: Yup.array()
      .min(1, "At least one document  is required!") //if this is true error message will not be displayed
      .test(
        "singleDocument",
        "Only one document can be uploaded when selecting multiple production orders",
        (value, context) => {
          const { parent } = context; // Access form values
          // Check if there is only one document uploaded when selecting multiple production orders
          return !(value.length > 1 && parent?.prod_orders?.length > 1);
        }
      )
      .required("Document file(s) are required"),
  });

  // form handler for documents upload
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,

    resetForm,
    validateForm,
  } = useFormik({
    initialValues: uploadForm,
    validationSchema: uploadSchema,
    onSubmit: async (values) => {
      const formData = new FormData();
      // formData.set("description", values.description);
      // formData.set('documents', values.documents);

      // Append each file to FormData individually
      values.documents.forEach((doc) => {
        formData.append(`documents`, doc);
      });

      // Convert prod_orders array to JSON string and append to FormData
      formData.set("prod_orders", JSON.stringify(values.prod_orders_upload));
      // formData.set("orderNum", lists.length ? lists[0]?.OrderNum : "");

      try {
        const response = await axiosPrivate.post("docs", formData);
        if (response.status === 200) {
          showSuccessToast("Documents uploaded Successfully");
          setSelectedRows(null);
          supervisorFormik.resetForm();
          resetForm();
          setVisible(false);
        }
      } catch (error) {
        showErrorToast(error?.response?.data?.message);
      }
    },
  });

  useEffect(() => {
    // If running_stages is true, show confirmation dialog for updating release dates
    if (runningStages && releaseDate) {
      setRunningStagesDialog(true);
    }
  }, [runningStages, releaseDate]);

  useEffect(() => {
    // If running_stages is true, show confirmation dialog for updating release dates
    if (releaseDate && runningStages === false) {
      setReleaseDateDialog(true);
    }
  }, [releaseDate, runningStages]);

  useEffect(() => {
    // If running_stages is true, show confirmation dialog for updating release dates
    if (supervisorId) {
      setUpdateSupervisorDialog(true);
    }
  }, [supervisorId]);

  useEffect(() => {
    if (lineStatus) {
      setLineStatusDialog(true);
    }
  }, [lineStatus]);

  useEffect(() => {
    supervisorFormik.setFieldValue(
      "prod_orders",
      selectedRows ? selectedRows?.map((line) => line?.ProductionOrder) : []
    );
    setFieldValue(
      "prod_orders_upload",
      selectedRows ? selectedRows?.map((line) => line?.ProductionOrder) : []
    );
  }, [selectedRows]);

  // Define initial form values

  const [filters, setFilters] = useState({
    global: { value: '', matchMode: FilterMatchMode.CONTAINS },
    productionOrder: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    prodGroup: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    partNum: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
  });

    const minimumDate = new Date();
    minimumDate.setHours(0, 0, 0, 0);

  // Effect to update the list when the data changes
  useEffect(() => {
    if (lists) {
      // Add index property to each item for numbering
      setNewList(
        lists.map((item, index) => ({
          ...item,
          index: index + 1,
          tooltipTarget: `tooltip-${index}`,
        }))
      );
      setshowPaginator(paginator);
    }
  }, [lists]);

  const productionOrders = useMemo(() => {
    return Array.from(new Set(newList?.map((job) => job.ProductionOrder))).map(
      (productionOrder) => ({
        value: productionOrder,
        label: productionOrder,
      })
    );
  }, [newList]);

  const supervisorSchema = Yup.object({
    prod_orders: Yup.array().min(1, "Production Order is required!"),
    user_id: Yup.object().required("Supervisor is required"),
  });

  //Form Handler for supervisor assignation
  const supervisorFormik = useFormik({
    initialValues: {
      prod_orders: [],
      user_id: "",
      update_stages: updateStages,
    },
    validationSchema: supervisorSchema,
    onSubmit: async (values) => {
      try {
        const response = await axiosPrivate.post("job/assign_user", {
          prod_orders: values?.prod_orders?.length ? values.prod_orders : [productionOrder],
          user_id: values?.user_id?.value,
          update_stages: updateStages,
        });
        if (response.status === 200) {
          showSuccessToast("Supervisor Assigned Successfully");
          updateLinesData ? updateLinesData() : updateJobData();
          setVisible(false);
          setSelectedRows(null);
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  // Template for the row group header
  // const rowExpansionTemplate = (data) => {
  //   const filteredStages = stages.filter(
  //     (stage) => stage.product_id === selectedProductId
  //   ); // Filter stages based on selected product ID
  //   return (
  //     <div className='p-3'>
  //       <h5>Stages for {data.ProductionOrder}</h5>
  //       <DataTable value={filteredStages}>
  //         {stagesHeaders?.map(
  //           (header, index) =>
  //             header.field !== "status" && (
  //               <Column
  //                 key={index}
  //                 field={header?.field}
  //                 header={header?.name}
  //                 sortable={header?.sortable}
  //               />
  //             )
  //         )}
  //         {/* <Column
  //           field='status'
  //           header='Status'
  //           body={statusBodyTemplate}
  //           sortable
  //         /> */}
  //       </DataTable>

  //       <div className=' text-right mt-4'></div>
  //     </div>
  //   );
  // };

 
  const debounce = (func, delay) => {
    let debounceTimer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  //for global search
  // const onGlobalFilterChange = async (value) => {
  //   try {
  //     const response = await axiosPrivate.get(
  //       `global_search?type=lines&keyword=${value}`
  //     );
  //     if (response.status === 200) {
  //       const filteredData = response?.data?.data;     
          
  //       setNewList(
  //         filteredData.map((item, index) => ({
  //           ...item,
  //           index: index + 1,
  //           tooltipTarget: `tooltip-${index}`,
  //         }))
  //       );
  //     }
  //   } catch (error) {
  //     //  console.log(error.message);
  //   }
  // };

  const debouncedFilterChange = useCallback(
    debounce((value) => {
      fetchLinesData(value , null);
    }, 800),
    []
  );
  const renderHeader = () => {
    const value = filters["global"] ? filters["global"].value : "";
    return (
      <div className='row d-flex justify-content-between align-items-center pl-2 pr-2 '>
        <div className='text-right'>
          <button
            type='button'
            className='btn btn-primary ml-2'
            onClick={() => setVisible((prev) => !prev)}
            style={{ marginRight: "2rem" }}
          >
            Add Details
          </button>
          <button
            type='button'
            className='btn btn-primary'
            onClick={() => setBulkReleaseDialog((prev) => !prev)}
          >
            Release
          </button>
          {filters["global"].value?.length > 1 ? (
            <span className='ml-2 pt-2'>
              Showing total {newList?.length} entries...
            </span>
          ) : null}
        </div>
        <InputText
          type='search'
          value={value || ""}
          onChange={(event) => {
            const { value } = event.target;
            setPageNo(1);
            setFilters((prevFilters) => ({
              ...prevFilters,
              global: { value: value, matchMode: "contains" },
            }));
            if (value?.length > 1) {
              debouncedFilterChange(value);
            }

            if (value === "") {
              setNewList(
                lists.map((item, index) => ({ ...item, index: index + 1 }))
              );
            }
          }}
          placeholder='Global Search'
        />
      </div>
    );
  };

  const templateEditor = (options) => {
    return (
      <Dropdown
        value={options?.value}
        options={options?.rowData?.products || []}
        onChange={(e) => options.editorCallback(e.value)}
        placeholder={
          options?.rowData.product_template_id
            ? options?.rowData?.products?.find(
                (product) =>
                  product.value === options.rowData?.product_template_id
              )?.label
            : "Select Product"
        }
      />
    );
  };

  const templateBodyTemplate = (rowData) => {
    return rowData?.product_template_id
      ? rowData?.products?.filter(
          (product) => product?.value === rowData?.product_template_id
        )[0]?.label
      : "-";
  };

  const handleRowUpdate = async (newData) => {
    setRunningStages(newData?.running_stages);

    setProductionOrder(
      newData?.ProductionOrder ? newData.ProductionOrder : null
    );
    setReleaseDate(newData?.releaseDate ? newData.releaseDate : null);
    setSupervisorId(newData?.supervisor ? newData.supervisor : null);
    setLineStatus(newData?.new_line_status ? newData?.new_line_status : null);

    if (releaseDate) {
      handleUpdateReleaseDate();
    }

    try {
      const promises = [];

      if (newData?.template) {
        promises.push(
          axiosPrivate.put(`job/set_product/${newData?.ProductionOrder}`, {
            productId: newData?.template,
          })
        );
      }

     

      const [response] = await Promise.all(promises);

      if (
        response &&
        response.status === 200
        // || (res && res.status === 200)
        // || (result && result.status === 200)
      ) {
        showSuccessToast("Line Updated successfully");
        updateLinesData ? updateLinesData() : updateJobData();
      }
    } catch (err) {
      if (err?.response?.status === 404) {
        showErrorToast(err?.response?.data?.message);
      } else if (err?.response?.status === 400) {
        showErrorToast(err?.response?.data?.message);
      }
    }
  };

  const supervisorBodyTemplate = (rowData) => {
    const supervisor = supervisors?.find(
      (supervisor) =>
        Number(supervisor.value) === Number(rowData?.assigned_user)
    );

    return <span>{rowData?.assigned_user ? supervisor?.label : "-"}</span>;
  };

  const supervisorEditor = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={supervisors}
        onChange={(e) => options.editorCallback(e.value)}
        placeholder={
          options?.rowData.assigned_user
            ? supervisors?.find(
                (supervisor) =>
                  Number(supervisor.value) ===
                  Number(options.rowData?.assigned_user)
              )?.label
            : "Select Supervisor"
        }
      />
    );
  };

  // const onDropdownChange = (e, rowData) => {
  //   // setSelectedProductId(e.value); // Update selected product ID
  //   // const updatedList = newList.map((item) => {
  //   //   if (item.id === rowData.id) {
  //   //     return { ...item, product_template_id: e.value };
  //   //   }
  //   //   return item;
  //   // });
  //   // updateStages(e.value);
  //   // setNewList(updatedList);
  // };
  const onRowEditComplete = (e) => {
    const { newData } = e;

    handleRowUpdate(newData);
  };

  const header = renderHeader();

  const renderProductionOrderBody = (rowData) => (
    <>
      <span
        id={rowData?.tooltipTarget}
        className='p-overlay-badge'
      >
        {rowData.ProductionOrder}
        <FaCircleInfo
          className='ml-1 '
          style={{ cursor: "pointer" }}
        />
      </span>
      <Tooltip
        target={`#${rowData.tooltipTarget}`}
        mouseTrack
        mouseTrackLeft={10}
        content={rowData?.LineDesc}
      />
    </>
  );

  const viewButtonTemplate = (rowData) => {
    return (
      <Link
        to={`view/${rowData.OrderNum}`}
        style={{ border: "none", background: "none" }}
      >
        <GrFormView
          size={30}
          color='#ff0000'
        />
      </Link>
    );
  };

  // const docsButtonTemplate = (rowData) => {
  //   return (
  //     <IoDocumentAttachOutline
  //       size={30}
  //       color='#007BFF'
  //       style={{
  //         cursor: "pointer",
  //         padding: "5px",

  //         backgroundColor: "initial",
  //         borderRadius: "5px",
  //       }}
  //       onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "#f0f0f0")}
  //       onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "initial")}
  //     />
  //   );
  // };

  const releaseDateBodyTemplate = (rowData) => {
    return (
      <span>
        {rowData.LineRelDt
          ? new Date(rowData.LineRelDt).toLocaleDateString("en-GB")
          : "-"}
      </span>
    );
  };

  const releaseDateEditor = (options) => {
    let maxDate = new Date(options?.rowData?.ShipDt);
    if (maxDate) {
      // Subtract one day's worth of milliseconds
      maxDate = new Date(maxDate.getTime() - 24 * 60 * 60 * 1000);
    }
    const minDate = options?.rowData?.JobRelDt
      ? new Date(options?.rowData?.JobRelDt)
      : new Date();
    minDate.setHours(0, 0, 0, 0); // Set hours to midnight

    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.editorCallback(e.value)}
        // showIcon
        readOnlyInput
        placeholder={
          options?.rowData?.LineRelDt
            ? new Date(options.rowData?.LineRelDt).toLocaleDateString("en-GB")
            : "Select Date"
        }
        dateFormat='dd/mm/yy'
        minDate={minDate}
        maxDate={maxDate}
      />
    );
  };

  const statusEditor = (options) => {
    const lineOptions =
      options?.rowData?.line_status === 1
        ? [
            {
              label: "Hold",
              value: 2,
            },
          ]
        : options?.rowData?.line_status === 2
        ? [
            {
              label: "Resume",
              value: 3,
            },
          ]
        : options?.rowData?.line_status === 0
        ? [
            {
              label: "Hold",
              value: 2,
            },
          ]
        : [];
    return (
      <Dropdown
        value={options?.value}
        options={lineOptions}
        onChange={(e) => options?.editorCallback(e.value)}
        placeholder={
          options?.rowData?.line_status === 0
            ? "Pending"
            : options?.rowData?.line_status === 1
            ? "In Progress"
            : options?.rowData?.line_status === 2
            ? "Hold"
            : options?.rowData?.line_status === 3
            ? "Resume"
            : options?.rowData?.line_status === 4
            ? "Completed"
            : options?.rowData?.line_status === 5
            ? "Re-open"
            : "Pending"
        }
        itemTemplate={(option) => {
          return (
            <Tag
              value={option?.label}
              severity={
                option.value === 0
                  ? "warning"
                  : option.value === 1
                  ? "info"
                  : option.value === 2
                  ? "danger"
                  : option.value === 3
                  ? "info"
                  : option.value === 4
                  ? "success"
                  : option.value === 5
                  ? "info"
                  : "primary"
              }
            ></Tag>
          );
        }}
      />
    );
  };
  const statusBodyTemplate = (rowData) => {
    return (
      <Tag
        value={
          rowData?.line_status === 0
            ? "Pending"
            : rowData?.line_status === 1
            ? "In Progress"
            : rowData?.line_status === 2
            ? "Hold"
            : rowData?.line_status === 3
            ? "Resume"
            : rowData?.line_status === 4
            ? "Completed"
            : rowData?.line_status === 5
            ? "Re-open"
            : "Pending"
        }
        severity={
          rowData.line_status === 0
            ? "warning"
            : rowData.line_status === 1
            ? "info"
            : rowData.line_status === 2
            ? "danger"
            : rowData.line_status === 3
            ? "info"
            : rowData.line_status === 4
            ? "success"
            : rowData.line_status === 5
            ? "info"
            : "primary"
        }
      />
    );
  };

  const handleUpdateReleaseDate = async () => {
    const options = { day: "2-digit", month: "2-digit", year: "2-digit" };
    try {
      const response = await axiosPrivate.put(
        `job/release_line/${productionOrder}`,
        {
          date: new Date(releaseDate).toLocaleDateString("en-GB", options),
        }
      );
      if (response.status === 200) {
        showSuccessToast("Job Updated Successfully");
        updateLinesData ? updateLinesData() : updateJobData();
      }
    } catch (error) {
      showErrorToast(error?.response?.data?.message);
    } finally {
     
      setReleaseDate(null);
      setRunningStagesDialog(false);
      setReleaseDateDialog(false);
    }
  };

  const handleUpdateSupervisor = async (flag) => {
    const prod_orders = [productionOrder];
    if (supervisorId) {
      try {
        const response = await axiosPrivate.post("job/assign_user", {
          prod_orders,
          user_id: supervisorId,
          update_stages: flag,
        });
        if (response.status === 200) {
          showSuccessToast("Supervisor Updated Successfully");
          updateLinesData ? updateLinesData() : updateJobData();
        }
      } catch (error) {
        showErrorToast(error.response.data.message);
      } finally {
        
        setRunningStagesDialog(false);
      }
    }
  };

  const handleLineStatusUpdate = async () => {
    if (lineRemark.length) {
      try {
        const response = await axiosPrivate.put(
          `tracking_stages/hold_resume_line/${productionOrder}`,
          {
            remarks: `${lineRemark}: ${
              userData?.userName
            }\n[${new Date().toLocaleString()}]\n`,
            status: lineStatus === 3 ? 1 : lineStatus,
          }
        );
        if (response.status === 200) {
          showSuccessToast("Job Updated Successfully");
          updateLinesData ? updateLinesData() : updateJobData();
        }
      } catch (error) {
        showErrorToast(error?.response?.data?.message);
      } finally {
        setLineRemark(null);
       
        setLineStatus(null);
        setLineRemark("");
        setLineStatusDialog(false);
        setLineRemark("");
      }
    } else {
      showErrorToast("Please enter remarks");
      setLineRemark("");
    }
  };



    const handleBulkRelease = async (bulkRelaseDate, selectedRows) => {
      if (!bulkRelaseDate) {
        setBulkReleaseDialog(false);
        showErrorToast("Please Select Release date!");
        return;
      } else if (!selectedRows?.length) {
        setBulkReleaseDialog(false);
        showErrorToast("Please Select at least one lines!");
        return;
      }
      const options = { day: "2-digit", month: "2-digit", year: "2-digit" };
      try {
        const response = await axiosPrivate.put(`job/bulk_release`, {
          date: new Date(bulkRelaseDate).toLocaleDateString("en-GB", options),
          prod_orders: selectedRows?.map((row) => row?.ProductionOrder),
          order_nums: []
        });
        if (response.status === 200) {
          showSuccessToast("Lines Released Successfully");
         updateLinesData ? updateLinesData() : updateJobData();
        }
      } catch (error) {
        if (error?.response?.status === 422) {
         
          showErrorToast(error?.response?.data?.errors[0]?.msg);
        }
        else {
          showErrorToast(error?.response?.data?.message);
        }
      } finally {
        setBulkReleaseDate("");
        setSelectedRows(null);
        setBulkReleaseDialog(false);
      }
    };



    const onPageChange = (event) => {
      setFirst(event.first);
      setPageNo(event.page + 1);
      setOffset(event.rows);
      console.log(event);
    };
    const onNextPage = () => {
     
      const newPage = Number(currentPage) + 1;
      setPageNo(newPage);
      setCurrentPage(newPage);
    };

    const onPreviousPage = () => {
      const newPage = Number(currentPage) - 1;
      setPageNo(newPage);
      setCurrentPage(newPage);
    };

    const handleRowsChange = (event) => {
      setOffset(Number(event.target.value));
      setFirst(0);
      setPageNo(1);
    };

    
    const onLastPage = () => {
     
      setPageNo(linesPagination.totalPages);
      setCurrentPage(linesPagination.totalPages);
      setFirst((linesPagination.totalPages - 1) * offset);
    };

    const onFirstPage = () => {
      setPageNo(1);
      setCurrentPage(1);
      setFirst(0);
    };

      const handlePageInputChange = (event) => {
        const value = event.target.value;
        if (
          value === "" ||
          (Number(value) >= 1 && Number(value) <= linesPagination.totalPages)
        ) {
          setPageInput(value);
        }
      };

    const goToPage = (event) => {
      event.preventDefault();
      setPageNo(pageInput);
      setCurrentPage(pageInput);
      setFirst((pageInput - 1) * offset);
    };



    
  return (
    <>
      {/* Dialog for upload documents & Assign Supervisor */}
      <Dialog
        header='Add Details'
        visible={visible}
        style={{ width: "35vw" }}
        onHide={() => {
          setVisible(false);
          supervisorFormik.resetForm();
          resetForm();
          setFileNames([]);
        }}
      >
        <div className='d-flex justify-content-between m-2 '>
          <span className='font-weight-bold '>Assign Supervisor</span>
          <InputSwitch
            checked={checked}
            onChange={(e) => setChecked(e.value)}
          />
          <span className='font-weight-bold mr-2'>Upload Files</span>
        </div>
        {/* upload documents form */}
        {checked ? (
          <form
            className='form-horizontal'
            onSubmit={handleSubmit}
          >
            <div className='form-group'>
              <label className='col-sm control-label'>Production Order</label>
              <div className='col-sm-12'>
                <Select
                  options={productionOrders}
                  name='prod_orders_upload'
                  id='prod_orders_upload'
                  value={values?.prod_orders_upload?.map(
                    (production_order) => ({
                      value: production_order,
                      label: production_order,
                    })
                  )}
                  isMulti
                  onChange={(selectedOptions) => {
                    setFieldValue(
                      "prod_orders_upload",
                      selectedOptions?.map((option) => option.value)
                    );
                    validateForm();
                  }}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Ensure the menu appears above other elements
                    menu: (base) => ({ ...base, overflow: "visible" }), // Apply overflow visible to the menu
                  }}
                  menuPortalTarget={document.body} // Render the menu in body to avoid clipping
                />
                {touched.prod_orders_upload && errors.prod_orders_upload && (
                  <div
                    style={{
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {errors?.prod_orders_upload}
                  </div>
                )}
              </div>
            </div>

            <div className='form-group'>
              <label className='col-sm control-label'>Upload Document</label>
              <div className='col-sm-12'>
                <div className='mb-3'>
                  <input
                    className='form-control'
                    type='file'
                    name='documents'
                    onBlur={handleBlur}
                    multiple // Allow multiple file selection
                    onChange={(e) => {
                      const selectedFiles = Array.from(e.target.files); // Convert FileList to an array
                      const fileNames = selectedFiles.map((file) => file.name); // Extract file names
                      setFieldValue("documents", selectedFiles); // Set files as an array
                      setFileNames(fileNames.join(", ")); // Set concatenated file names
                    }}
                    id='formFileLines'
                  />

                  {touched.documents && errors.documents && (
                    <div
                      style={{
                        color: "red",
                        fontSize: "12px",
                      }}
                    >
                      {errors.documents}
                    </div>
                  )}
                </div>
                {/* Display selected file names */}
                {fileNames && <div>Selected Files: {fileNames}</div>}
              </div>
            </div>

            <div className='form-group'>
              <div className='col-sm-12 col-sm-offset-2 text-right pt-2'>
                <button
                  type='submit'
                  id='button_2'
                  className='btn btn-primary'
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        ) : (
          //supervisor Form Component
          <form
            className='form-horizontal'
            onSubmit={supervisorFormik.handleSubmit}
          >
            <div className='form-group'>
              <label className='col-sm control-label'>Production Order</label>
              <div className='col-sm-12'>
                <Select
                  options={productionOrders}
                  name='prod_orders'
                  id='prod_orders'
                  value={supervisorFormik.values?.prod_orders?.map(
                    (production_order) => ({
                      value: production_order,
                      label: production_order,
                    })
                  )}
                  isMulti
                  onChange={(selectedOptions) => {
                    supervisorFormik.setFieldValue(
                      "prod_orders",
                      selectedOptions?.map((option) => option.value)
                    );
                  }}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Ensure the menu appears above other elements
                    menu: (base) => ({ ...base, overflow: "visible" }), // Apply overflow visible to the menu
                  }}
                  menuPortalTarget={document.body} // Render the menu in body to avoid clipping
                />
                {supervisorFormik.touched.prod_orders &&
                supervisorFormik.errors.prod_orders ? (
                  <div
                    style={{
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {supervisorFormik.errors.prod_orders}
                  </div>
                ) : null}
              </div>
            </div>
            <div className='form-group'>
              <label className='form-label'>Supervisor</label>
              <div className='col-sm-12'>
                <Select
                  options={supervisors}
                  value={supervisorFormik.values?.user_id}
                  onChange={(selectedOption) =>
                    supervisorFormik.setFieldValue("user_id", selectedOption)
                  }
                  name='user_id'
                  id='user_id'
                  placeholder='Select Supervisor'
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Ensure the menu appears above other elements
                    menu: (base) => ({ ...base, overflow: "visible" }), // Apply overflow visible to the menu
                  }}
                  menuPortalTarget={document.body} // Render the menu in body to avoid clipping
                />
                {supervisorFormik?.touched?.user_id &&
                  supervisorFormik?.errors?.user_id && (
                    <div
                      style={{
                        color: "red",
                        fontSize: "12px",
                      }}
                    >
                      {supervisorFormik?.errors?.user_id}
                    </div>
                  )}
              </div>
            </div>
            <div className='form-group'>
              <div>
                <div className='d-flex justify-content-start align-items-center'>
                  <Checkbox
                    inputId='updateStagesCheckbox'
                    checked={updateStages}
                    onChange={(e) => setUpdateStages(e.checked)}
                  />
                  <label
                    className='ml-2 mt-1'
                    htmlFor='updateStagesCheckbox'
                  >
                    Update Stage Supervisors
                  </label>
                </div>
              </div>
            </div>
            <div className='form-group'>
              <div className='col-sm-12 col-sm-offset-2 text-right pt-2'>
                <button
                  type='submit'
                  id='button_2'
                  className='btn btn-primary'
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        )}
      </Dialog>

      <Dialog
        visible={bulkReleaseDialog}
        onHide={() => {
          setBulkReleaseDialog(false);
          setBulkReleaseDate("");
        }}
        header='Confirmation'
        footer={
          <>
            <Button
              label='Cancel'
              className='btn btn-secondary'
              onClick={() => {
                setBulkReleaseDialog(false);
                setBulkReleaseDate("");
              }}
            />
            <Button
              label='Proceed'
              className='btn btn-primary ml-3'
              onClick={() => {
                handleBulkRelease(bulkRelaseDate, selectedRows);
              }}
            />
          </>
        }
      >
        <>
          <div
            className='form-group d-flex align-items-center justify-content-start w-100'
            style={{ minWidth: "300px" }}
          >
            <div>
              <strong>Select Release Date : </strong>
            </div>
            <div className='ml-3'>
              <Calendar
                value={bulkRelaseDate}
                onChange={(e) => setBulkReleaseDate(e.value)}
                showIcon
                placeholder={"Select Date"}
                readOnlyInput
                minDate={minimumDate}
                dateFormat='dd/mm/yy'
              />
            </div>
          </div>
        </>
      </Dialog>

      {/* Data Table for lines */}
      <div style={{ width: "100%", overflowX: "auto" }}>
        <DataTable
          value={newList}
          header={header}
          editMode='row'
          onRowEditComplete={onRowEditComplete}
          // expandedRows={expandedRows}
          // onRowToggle={(e) => {
          //   setExpandedRows(e.data);
          // }}
          // rowExpansionTemplate={rowExpansionTemplate}
          // paginator

          size='small'
          // scrollable={!showpaginator}
          // scrollHeight={showpaginator ? false : "480px"}
          // filters={filters}
          onFilter={(e) => setFilters(e.filters)}
          selection={selectedRows}
          selectionMode='checkbox'
          onSelectionChange={(e) => setSelectedRows(e.value)}
          dataKey='id'
          emptyMessage={"No Data Found!"}
          rows={offset}
          onPage={onPageChange}
          first={first}
          tableStyle={{ minWidth: "1600px" }}
        >
          {/* Columns definition */}
          <Column
            selectionMode='multiple'
            headerStyle={{ width: "3rem" }}
          ></Column>

          {showpaginator ? (
            <Column
              field='CustomerName'
              header='Customer Name'
              headerStyle={{ minWidth: "300px" }}
              sortable={true}
            />
          ) : null}

          <Column
            field='plant'
            header='Plant'
            sortable={true}
          />

          <Column
            field='OrderNum'
            header='Sales Order'
            sortable={true}
          />
          <Column
            field='ProductionOrder'
            header='Production Order'
            body={renderProductionOrderBody}
            sortable={true}
          />

          <Column
            field='OrderQty'
            header='OrderQty'
            sortable={true}
          />
          <Column
            field='OrderLine'
            header='Order Line'
            sortable={true}
          />

          <Column
            field='ProdGroup'
            header='Product Group'
            headerStyle={{ minWidth: "180px" }}
            sortable={true}
          />
          <Column
            field='template'
            header='Stage Template'
            headerStyle={{ minWidth: "130px" }}
            editor={(options) => templateEditor(options)}
            body={templateBodyTemplate}
          />
          <Column
            field='IndianAmt'
            header='Line Amount'
            sortable={true}
          />
          <Column
            field='ShipDt'
            header='Ship Dt.'
            body={(rowData) =>
              rowData?.ShipDt
                ? new Date(rowData?.ShipDt).toLocaleDateString("en-GB")
                : "-"
            }
            sortable={true}
          />
          <Column
            field='supervisor'
            header='Line Supervisor'
            headerStyle={{ minWidth: "180px" }}
            editor={(options) => supervisorEditor(options)}
            body={supervisorBodyTemplate}
          />

          <Column
            field='releaseDate'
            header='Line ReleaseDt.'
            editor={releaseDateEditor}
            body={releaseDateBodyTemplate}
            // sortable={true}
          />
          <Column
            field='new_line_status'
            header='Line Status'
            body={statusBodyTemplate}
            editor={(options) => statusEditor(options)}
          />

          {isListingPage || showpaginator ? (
            <Column
              field='action'
              body={viewButtonTemplate}
              headerStyle={{ width: "1%" }}
            />
          ) : null}

          <Column
            rowEditor={true}
            headerStyle={{ textAlign: "left", minWidth: "80px" }}
          />
          {/* {!showpaginator ? (
          <Column
            field='action'
            body={docsButtonTemplate}
            headerStyle={{ width: "1%" }}
          />
        ) : null} */}
        </DataTable>
      {showpaginator ?  <>
            <div
              style={{
                margin: "10px 0px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button
                onClick={onFirstPage}
                disabled={currentPage === 1}
                style={{
                  border: "none",
                  background: "none",
                  cursor: "pointer",
                }}
              >
                <FaAngleDoubleLeft size={20} />
              </button>
              <button
                onClick={onPreviousPage}
                disabled={currentPage === 1}
                style={{
                  border: "none",
                  background: "none",
                  cursor: "pointer",
                }}
              >
                <FaAngleLeft size={20} />
              </button>
              <span style={{ margin: "0 10px" }}>
                Showing Page {currentPage} of {linesPagination.totalPages}{" "}
                from total {linesPagination.totalRecords} entries
              </span>
              <button
                onClick={onNextPage}
                disabled={currentPage === Math.ceil(totalJobs / offset)}
                style={{
                  border: "none",
                  background: "none",
                  cursor: "pointer",
                }}
              >
                <FaAngleRight size={20} />
              </button>
              <button
                onClick={onLastPage}
                disabled={currentPage === Math.ceil(totalJobs / offset)}
                style={{
                  border: "none",
                  background: "none",
                  cursor: "pointer",
                }}
              >
                <FaAngleDoubleRight size={20} />
              </button>
              <select
                value={offset}
                onChange={handleRowsChange}
                style={{ marginLeft: "10px" }}
              >
                <option value={20}>20</option>
                <option value={40}>40</option>
                <option value={60}>60</option>
                <option value={80}>80</option>
              </select>
            </div>
            <form
              onSubmit={goToPage}
              style={{ marginLeft: "15px" }}
              className='d-flex justify-content-end align-items-center mr-3'
            >
              <span>Go to Page : </span>
              <input
                type='number'
                value={pageInput}
                onChange={handlePageInputChange}
                min={1}
                max={Math.ceil(totalJobs / offset)}
                style={{
                  width: "40px",
                  textAlign: "center",
                  marginLeft: "10px",
                }}
              />
              <button
                type='submit'
                className='btn btn-primary'
                style={{ marginLeft: "15px", padding: "3px 8px " }}
              >
                Go
              </button>
            </form>
          </> : null}
         
      
      </div>
      {/* Confirmation dialog for running stages */}
      <Dialog
        visible={runningStagesDialog}
        onHide={() => {
          setRunningStagesDialog(false);
          setReleaseDate(null);
          setRunningStages(null);
        }}
        header='Confirmation'
        footer={
          <>
            <Button
              label='No'
              className='btn btn-secondary'
              onClick={() => {
                setRunningStagesDialog(false);
                setReleaseDate(null);
                setRunningStages(null);
              }}
            />
            <Button
              label='Yes'
              className='btn btn-primary ml-3 '
              onClick={() => {
                setRunningStagesDialog(false);
                handleUpdateReleaseDate();
              }}
            />
          </>
        }
      >
        <p>
          Running stages detected for this Sales Order. Do you still want to
          proceed?
        </p>
      </Dialog>

      {/* Confirmation dialog for release Date update */}
      <Dialog
        visible={releaseDateDialog}
        onHide={() => {
          setReleaseDateDialog(false);
          setRunningStagesDialog(false);
          setReleaseDate(null);
          setRunningStages(null);
        }}
        header='Confirmation'
        footer={
          <>
            <Button
              label='Cancel'
              className='btn btn-secondary'
              onClick={() => {
                setReleaseDateDialog(false);
                setRunningStagesDialog(false);
                setReleaseDate(null);
                setRunningStages(null);
              }}
            />
            <Button
              label='Proceed'
              className='btn btn-primary ml-3'
              onClick={() => {
                handleUpdateReleaseDate();
              }}
            />
          </>
        }
      >
        <div>
          <span>Are you sure you update the release date for this line!</span>
        </div>
      </Dialog>

      {/* 
        Confirmation dialog for updating supervisors
       */}
      <Dialog
        visible={updateSupervisorDialog}
        onHide={() => {
          setUpdateSupervisorDialog(false);
          setSupervisorId(null);
        }}
        header='Confirmation'
        footer={
          <>
            <Button
              label='No'
              className='btn btn-secondary'
              onClick={() => {
                // Call handleUpdateSupervisor with flag set to false
                handleUpdateSupervisor(false);
                // Close the dialog
                setUpdateSupervisorDialog(false);
                // Clear the supervisorId
                setSupervisorId(null);
              }}
            />
            <Button
              label='Yes'
              className='btn btn-primary ml-3'
              onClick={() => {
                // Call handleUpdateSupervisor with flag set to true
                setUpdateSupervisorDialog(true);
                handleUpdateSupervisor(true);
                // Clear the supervisorId
                setSupervisorId(null);
              }}
            />
          </>
        }
      >
        {/* Prompt user for confirmation */}
        <p>Do you want to update the stage supervisors for this line also!</p>
      </Dialog>

      {/* Confirmation dialog for job status upload */}
      <Dialog
        visible={lineStatusDialog}
        onHide={() => {
          setLineStatusDialog(false);
          setLineStatus(null);
          setLineRemark("");
        }}
        header='Confirmation'
        footer={
          <>
            <Button
              label='Cancel'
              className='btn btn-secondary'
              onClick={() => {
                setLineStatusDialog(false);
                setLineStatus(null);
                setLineRemark("");
              }}
            />
            <Button
              label='Proceed'
              className='btn btn-primary ml-3'
              onClick={() => {
                handleLineStatusUpdate();
              }}
            />
          </>
        }
      >
        <div>
          {lineStatus === 3 ? (
            <span>Are you sure you want to resume this line!</span>
          ) : (
            <span>Are you sure you want to hold this line!</span>
          )}

          <div className='d-flex justify-content-start align-items-start flex-column '>
            <InputTextarea
              value={lineRemark}
              onChange={(e) => setLineRemark(e.target.value)}
              rows={3}
              cols={60}
            />
            {!lineRemark.length ? (
              <span
                span
                style={{ color: "red", fontSize: "12px" }}
              >
                Remarks required!
              </span>
            ) : null}
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default DataTableLines;
