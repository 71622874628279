import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tag } from "primereact/tag";
import { useFormik } from "formik";
import Select from "react-select";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Dropdown } from "primereact/dropdown";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";
import axiosPrivate from "../../hooks/axiosPrivate";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import { MultiSelect } from "primereact/multiselect";
import { MdOutlineDoneOutline, MdOutlinePending } from "react-icons/md";
import { InputTextarea } from "primereact/inputtextarea";
import { FaCircleInfo } from "react-icons/fa6";
import { Tooltip } from "primereact/tooltip";

const DataTableStages = ({ lists, linesData, updateJobData, supervisors }) => {
  // State variables
  const [visible, setVisible] = useState(false);
  const [assignSupervisor, setAssignSupervisor] = useState(false);
  const [lines, setLines] = useState([]);
  const [selectedRows, setSelectedRows] = useState(null);
  const [newList, setNewList] = useState([]);
  // const [plannedStart, setPlannedStart] = useState();
  // const [plannedEnd, setPlannedEnd] = useState();
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    stageName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    status: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    supervisor: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    productionOrder: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
  });

  // Effect to update the list when the data changes
  useEffect(() => {
    setLines(linesData);
    if (lists) {
      // Add index property to each item for numbering
      setNewList(
        lists?.map((item, index) => ({
          ...item,
          index: index + 1,
          tooltipTarget: `tooltip-${index}`,
          tooltipRemark: `tooltip-remark-${index}`,
        }))
      );
    }
  }, [lists, linesData]);

  useEffect(() => {
    supervisorFormik.setFieldValue(
      "line_stage_ids",
      selectedRows ? selectedRows?.map((stage) => ({
        value: stage?.id,
        label: stage?.stage_name + "--" + stage?.production_order,
      })) : []
    );
  }, [selectedRows]);

  //form handler for adding a new stage to the a particular job
  const stageSchema = Yup.object({
    stage_name: Yup.string().required("Stage Name is required"),
    production_order: Yup.array().min(1, "Production Order is required"),
    user_id: Yup.object().required("Supervisor is required"),
    planned_start: Yup.date().required("Start Date is required"),
    planned_end: Yup.date().required("End Date is required"),
  });

  // Define initial form values
  const stageForm = {
    stage_name: "",
    stage_description: "",
    parent_stage_ids: [],
    user_id: '',
    production_order: [],
    planned_start: '',
    planned_end: '',
  };

  //formik hook for adding a new stage
  const stageFormik = useFormik({
    initialValues: stageForm,
    validationSchema: stageSchema,
    onSubmit: async (values) => {
      try {
        const options = { day: "2-digit", month: "2-digit", year: "2-digit" };
        const response = await axiosPrivate.post("stage/line", {
          ...values,
          user_id : stageFormik?.values?.user_id?.value,
          parent_stage_ids: stageFormik?.values?.parent_stage_ids.map(
            (stage) => stage.value
          ),
          planned_start: stageFormik?.values?.planned_start.toLocaleDateString(
            "en-GB",
            options
          ),
          planned_end: stageFormik?.values?.planned_end.toLocaleDateString(
            "en-GB",
            options
          ),
        });
        if (response?.status === 201) {
          updateJobData();
          showSuccessToast("Stage added successfully");
        }
      } catch (err) {
        console.log(err.message);
      }
    },
  });

  const productionOrders = Array.from(
    new Set(lines?.map((line) => line.ProductionOrder))
  )?.map((productionOrder) => ({
    value: productionOrder,
    label: productionOrder,
  }));

  const onRowEditComplete = async (e) => {
    const { data, newData } = e;

    const line_stage_ids = [];
    line_stage_ids.push(newData.id);

    try {
      const promises = [];

      if (newData?.supervisor) {
        promises.push(
          axiosPrivate.post("job/assign_user", {
            line_stage_ids,
            user_id: newData?.supervisor,
          })
        );
      }

      if (newData) {
        const options = { day: "2-digit", month: "2-digit", year: "2-digit" };
        const userData = JSON.parse(localStorage.getItem("userData")) || {};
        promises.push(
          axiosPrivate.put(`tracking_stages/update_line_stage/${newData.id}`, {
            prod_order: newData?.production_order,
            status:
              newData?.new_status >= 0 ? newData?.new_status : data?.status,
            flags: newData.new_flags
              ? JSON.stringify(
                  newData?.new_flags.map((flag) =>
                    flag === 0
                      ? { label: "Overdue", value: flag }
                      : flag === 1
                      ? { label: "Urgent", value: flag }
                      : flag === 2
                      ? { label: "Low", value: flag }
                      : {}
                  )
                )
              : data?.flags,
            remarks: newData?.new_remarks
              ? data?.remarks
                ? `${data?.remarks}\n${newData?.new_remarks}: ${
                    userData?.userName
                  }\n[${new Date().toLocaleString("en-GB")}]`
                : `${newData?.new_remarks}: ${
                    userData?.userName
                  }\n[${new Date().toLocaleString("en-GB")}]
                 `
              : data?.remarks,

            inter_stage_qac:
              newData.new_qc || newData.new_qc === 0
                ? newData.new_qc
                : data?.inter_stage_qac,
            planned_start: newData?.new_planned_start
              ? new Date(newData?.new_planned_start).toLocaleDateString(
                  "en-GB",
                  options
                )
              : new Date(data?.planned_start).toLocaleDateString(
                  "en-GB",
                  options
                ),
            planned_end: newData?.new_planned_end
              ? new Date(newData?.new_planned_end).toLocaleDateString(
                  "en-GB",
                  options
                )
              : new Date(data?.planned_end).toLocaleDateString(
                  "en-GB",
                  options
                ),
          })
        );
      }

      const [assign_user_response, tracking_stages_response] =
        await Promise.all(promises);

      if (
        assign_user_response?.status === 200 ||
        tracking_stages_response?.status === 200
      ) {
        showSuccessToast("Stage Updated Successfully");
        updateJobData();
        setAssignSupervisor(false);
       
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        showErrorToast(error.response.data.message);
      } else console.log(error);
    }
  };

  const onGlobalFilterChange = (event) => {
    const value = event.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <Tag
        value={
          rowData?.status === 0
            ? "Pending"
            : rowData?.status === 1
            ? "In Progress"
            : rowData?.status === 2
            ? "Hold"
            : rowData?.status === 3
            ? "Resume"
            : rowData?.status === 4
            ? "Completed"
            : rowData?.status === 5
            ? "Re-open"
            : "Pending"
        }
        severity={
          rowData?.status === 0
            ? "warning"
            : rowData?.status === 1
            ? "info"
            : rowData?.status === 2
            ? "danger"
            : rowData?.status === 3
            ? "info"
            : rowData?.status === 4
            ? "success"
            : rowData?.status === 5
            ? "info"
            : "primary"
        }
      />
    );
  };
  const statusEditor = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={[
          {
            label: "Pending",
            value: 0,
          },
          {
            label: "In Progress",
            value: 1,
          },
          {
            label: "Hold",
            value: 2,
          },
          // {
          //   label: "Resume",
          //   value: 3,
          // },
          {
            label: "Completed",
            value: 4,
          },
          // {
          //   label: "Re-open",
          //   value: 5,
          // },
        ]}
        onChange={(e) => options.editorCallback(e.value)}
        placeholder={
          options?.rowData?.status === 0
            ? "Pending"
            : options?.rowData?.status === 1
            ? "In Progress"
            : options?.rowData?.status === 2
            ? "Hold"
            // : options?.rowData?.status === 3
            // ? "Resume"
            : options?.rowData?.status === 4
            ? "Completed"
            // : options?.rowData?.status === 5
            // ? "Re-open"
            : "Pending"
        }
        itemTemplate={(option) => {
          return (
            <Tag
              value={option?.label}
              severity={
                option.value === 0
                  ? "warning"
                  : option.value === 1
                  ? "info"
                  : option.value === 2
                  ? "danger"
                  // : option.value === 3
                  // ? "info"
                  : option.value === 4
                  ? "success"
                  // : option.value === 5
                  // ? "info"
                  : "primary"
              }
            ></Tag>
          );
        }}
      />
    );
  };

  const flagsBodyTemplate = (rowData) => {
    const flagsData =
      typeof rowData?.flags === "string"
        ? JSON.parse(rowData?.flags)
        : rowData?.flags;

    return (
      <div>
        {rowData?.flags && Array.isArray(flagsData)
          ? flagsData?.map((flag, index) => (
              <Tag
                key={index}
                value={flag?.label}
                style={{ fontSize: "8px", padding: "1px" }}
                className='p-mr-1 p-text-sm m-1'
                severity={
                  flag?.label === "Overdue"
                    ? "danger"
                    : flag?.label === "Urgent"
                    ? "warning"
                    : flag?.label === "Low"
                    ? "info"
                    : "success"
                }
              />
            ))
          : "-"}
      </div>
    );
  };

  const remarksEditor = (options) => {
    return (
      <InputTextarea
        type='text'
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
        placeholder="Enter Remarks"
      />
    );
  };

  const remarksBodyTemplate = (rowData) => {
    return rowData?.remarks ? (
      <>
        <span
          id={rowData?.tooltipRemark}
          className='p-overlay-badge'
        >
          {rowData?.remarks ? rowData?.remarks.slice(0, 30) + "..." : "-"}
          <FaCircleInfo
            className='ml-1'
            style={{ cursor: "pointer" }}
          />
        </span>
        <Tooltip
          target={`#${rowData.tooltipRemark}`}
          mouseTrack
          mouseTrackLeft={10}
          content={rowData?.remarks}
        />
      </>
    ) : (
      <span className='pl-4'>-</span>
    );
  };

  const supervisorEditor = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={supervisors}
        onChange={(e) => options.editorCallback(e.value)}
        placeholder={
          options?.rowData.assigned_user
            ? supervisors?.find(
                (supervisor) =>
                  Number(supervisor?.value) ===
                  Number(options.rowData?.assigned_user)
              )?.label
            : "Select Supervisor"
        }
      />
    );
  };

  const supervisorBodyTemplate = (rowData) => {
    const supervisor = supervisors?.find(
      (supervisor) =>
        Number(supervisor?.value) === Number(rowData?.assigned_user)
    );

    return <span>{rowData?.assigned_user ? supervisor?.label : "-"}</span>;
  };

  const flagsEditor = (options) => {
    return (
      <MultiSelect
        className='w-100'
        filter
        value={Array.isArray(options.value) ? options.value : []} // Ensure options.value is an array
        options={[
          { label: "Overdue", value: 0 },
          { label: "Urgent", value: 1 },
          { label: "Low", value: 2 },
        ]}
        onChange={(e) => {
          options.editorCallback(
            Array.isArray(e.value) // Ensure e.value is an array
              ? e.value
              : []
          );
        }}
        placeholder={
          typeof options?.rowData?.flags === "string" &&
          JSON.parse(options?.rowData?.flags).length > 0 &&
          Array.isArray(JSON.parse(options?.rowData?.flags))
            ? JSON.parse(options?.rowData?.flags)
                .map((flag) => flag?.label)
                .join()
            : "--Select Flags--"
        }
        itemTemplate={(option) => (
          <Tag
            value={option.label}
            severity={
              option.label === "Overdue"
                ? "danger"
                : option.label === "Urgent"
                ? "warning"
                : "info"
            }
          />
        )}
      />
    );
  };

  const qcEditor = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={[
          { label: "Done", value: 1 },
          { label: "Pending", value: 0 },
        ]}
        onChange={(e) => options.editorCallback(e.value)}
        placeholder={
          options?.rowData.inter_stage_qac === 1
            ? "Done"
            : options?.rowData.inter_stage_qac === 0
            ? "Pending"
            : "Update QC"
        }
        itemTemplate={(option) => {
          return (
            <>
              {option?.value === 1 ? (
                <span>
                  <MdOutlineDoneOutline
                    color='green'
                    size={20}
                    style={{ marginRight: "8px" }}
                  />
                  Done
                </span>
              ) : (
                <span>
                  <MdOutlinePending
                    color='orange'
                    size={20}
                    style={{ marginRight: "8px" }}
                  />
                  Pending
                </span>
              )}
            </>
          );
        }}
      />
    );
  };

  const qcBodyTemplate = (rowData) => {
    return (
      <div>
        {rowData?.inter_stage_qac === 1 ? (
          <MdOutlineDoneOutline
            color='green'
            size={20}
          />
        ) : (
          <MdOutlinePending
            color='orange'
            size={20}
          />
        )}
      </div>
    );
  };

  // Custom editor for the deadline column
  const PlannedStartEditor = (options) => {
    let maxDate = options?.rowData?.ship_date
      ? new Date(options?.rowData?.ship_date)
      : new Date();
    if (maxDate) {
      // Subtract one day's worth of milliseconds
      maxDate = new Date(maxDate.getTime() - 24 * 60 * 60 * 1000);
    }

    let minDate = options?.rowData?.release_date
      ? new Date(options?.rowData?.release_date)
      : new Date();

    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.editorCallback(e.value)}
        // showIcon
        readOnlyInput
        placeholder={
          options?.rowData?.planned_start
            ? new Date(options.rowData.planned_start).toLocaleDateString(
                "en-GB"
              )
            : "-"
        }
        dateFormat='dd/mm/yy'
        maxDate={maxDate}
        minDate={minDate}
      />
    );
  };

  const PlannedEndEditor = (options) => {
    let maxDate = options?.rowData?.ship_date
      ? new Date(options?.rowData?.ship_date)
      : new Date();
    if (maxDate) {
      // Subtract one day's worth of milliseconds
      maxDate = new Date(maxDate.getTime() - 24 * 60 * 60 * 1000);
    }

    let minDate = options?.rowData?.release_date
      ? new Date(options?.rowData?.release_date)
      : new Date();
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.editorCallback(e.value)}
        // showIcon
        readOnlyInput
        placeholder={
          options?.rowData?.planned_end
            ? new Date(options.rowData.planned_end).toLocaleDateString(
                "en-GB"
              )
            : "-"
        }
        dateFormat='dd/mm/yy'
        maxDate={maxDate}
        minDate={minDate}
      />
    );
  };

  // Custom body template for the deadline column
  const PlannedStartTemplate = (rowData) => {
    return (
      <span>
        {rowData?.planned_start
          ? new Date(rowData.planned_start).toLocaleDateString("en-GB")
          : "-"}
      </span>
    );
  };

  const PlannedEndTemplate = (rowData) => {
    return (
      <span>
        {rowData?.planned_end
          ? new Date(rowData.planned_end).toLocaleDateString("en-GB")
          : "-"}
      </span>
    );
  };

  const supervisorSchema = Yup.object({
    line_stage_ids: Yup.array().min(1, "Stage is required!"),
    user_id: Yup.object().required("Supervisor is required"),
  });


  //form handler for supervisor assignation
  const supervisorFormik = useFormik({
    initialValues: {
      line_stage_ids: [],
      user_id: "",
    },
     validationSchema: supervisorSchema,
    onSubmit: async (values) => {
      const newPayload = {
       
        line_stage_ids: values.line_stage_ids?.map((item) => item.value),
        user_id: values.user_id?.value
      };

      try {
        const response = await axiosPrivate.post("job/assign_user", newPayload);
        if (response?.status === 200) {
          showSuccessToast("Supervisor Assigned Successfully");
          updateJobData();
          setAssignSupervisor(false);
          setSelectedRows(null);
          
        }
      } catch (error) {
        console.log(error);
      }

      // Log form values upon submission
      // Here you can perform further actions like submitting data to the server
    },
  });

  const renderHeader = () => {
    const value = filters["global"] ? filters["global"].value : "";

    return (
      <div className='row d-flex justify-content-between align-items-center pl-2 pr-2'>
        <div className=' text-right'>
          <button
            type='button'
            className='btn btn-primary'
            // data-toggle='modal'
            // data-target='#exampleModal'
            onClick={() => {
              setVisible((prev) => !prev);
            
            }
            }
            style={{ marginRight: "2rem" }}
          >
            <i className='fe fe-plus mr-2' />
            Add
          </button>

          <button
            type='button'
            className='btn btn-primary'
            // data-toggle='modal'
            // data-target='#exampleModal'
            onClick={() => {
              setAssignSupervisor((prev) => !prev)
           
            }}
            style={{ marginRight: "2rem" }}
          >
            Assign Supervisor
          </button>
        </div>
        <InputText
          type='search'
          value={value || ""}
          onChange={(e) => onGlobalFilterChange(e)}
          placeholder='Global Search'
        />
      </div>
    );
  };

  const header = renderHeader();

  const renderProductionOrderBody = (rowData) => (
    <>
      <span
        id={rowData?.tooltipTarget}
        className='p-overlay-badge'
      >
        {rowData.production_order}
        <FaCircleInfo
          className='ml-1'
          style={{ cursor: "pointer" }}
        />
      </span>
      <Tooltip
        target={`#${rowData.tooltipTarget}`}
        mouseTrack
        mouseTrackLeft={10}
        content={rowData?.LineDesc}
      />
    </>
  );

  return (
    <>
      {/* Dialog for adding a new stage*/}
      <Dialog
        header='Add Stage'
        visible={visible}
        style={{ width: "35vw" }}
        onHide={() => {
          setVisible(false);
          stageFormik.resetForm();
        }}
      >
        <form
          className='form-horizontal'
          onSubmit={stageFormik.handleSubmit}
        >
          <div className='form-group'>
            <label className='col-sm control-label'>Stage Name*</label>
            <div className='col-sm-12'>
              <input
                id='stage_name'
                name='stage_name'
                type='text'
                className='form-control'
                placeholder='Stage Name *'
                onChange={stageFormik.handleChange}
                onBlur={stageFormik.handleBlur}
                value={stageFormik.values.stage_name}
              />
              {stageFormik.touched.stage_name &&
                stageFormik.errors.stage_name && (
                  <div
                    style={{
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {stageFormik.errors.stage_name}
                  </div>
                )}
            </div>
          </div>
          <div className='form-group'>
            <label className='col-sm control-label'>Production Order*</label>
            <div className='col-sm-12'>
              <Select
                name='production_order'
                id='production_order'
                options={productionOrders}
                value={stageFormik.values?.production_order?.map(
                  (production_order) => ({
                    value: production_order,
                    label: production_order,
                  })
                )}
                isMulti
                onChange={(selectedOptions) => {
                  stageFormik.setFieldValue(
                    "production_order",
                    selectedOptions?.map((option) => option.value)
                  );
                }}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Ensure the menu appears above other elements
                  menu: (base) => ({ ...base, overflow: "visible" }), // Apply overflow visible to the menu
                }}
                menuPortalTarget={document.body} // Render the menu in body to avoid clipping
              />
              {stageFormik.touched.production_order &&
                stageFormik.errors.production_order && (
                  <div
                    style={{
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {stageFormik.errors.production_order}
                  </div>
                )}
            </div>
          </div>

          <div className='form-group'>
            <label className='col-sm control-label'>Parent Stages</label>
            <div className='col-sm-12'>
              <Select
                options={stageFormik.values?.production_order
                  ?.map((productionOrder) =>
                    lists?.filter(
                      (stage) => stage.production_order === productionOrder
                    )
                  )
                  ?.flat()
                  ?.map((stage) => ({
                    value: stage?.stage_id,
                    label: stage?.stage_name + "--" + stage?.production_order,
                  }))}
                name='parent_stage_ids'
                value={stageFormik.values?.parent_stage_ids?.map((stage) => ({
                  value: stage?.value,
                  label: stage?.label,
                }))}
                isMulti
                onChange={(selectedOptions) => {
                  stageFormik.setFieldValue(
                    "parent_stage_ids",
                    selectedOptions?.map((option) => ({
                      value: option.value,
                      label: option.label,
                    }))
                  );
                }}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Ensure the menu appears above other elements
                  menu: (base) => ({ ...base, overflow: "visible" }), // Apply overflow visible to the menu
                }}
                menuPortalTarget={document.body} // Render the menu in body to avoid clipping
              />
              {stageFormik.touched?.parent_stage_ids &&
                stageFormik.errors?.parent_stage_ids && (
                  <div
                    style={{
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {stageFormik.errors?.parent_stage_ids}
                  </div>
                )}
            </div>
          </div>

          <div className='col-md-12 col-sm-12'>
            <div className='form-group'>
              <label className='col-sm control-label'>Stage Supervisor*</label>
              <Select
                options={supervisors}
                value={stageFormik.values?.user_id}
                onChange={(selectedOption) =>
                  stageFormik.setFieldValue("user_id", selectedOption)
                }
                name='user_id'
                id='user_id'
                placeholder='Select Supervisor'
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Ensure the menu appears above other elements
                  menu: (base) => ({ ...base, overflow: "visible" }), // Apply overflow visible to the menu
                }}
                menuPortalTarget={document.body} // Render the menu in body to avoid clipping
              />
              {stageFormik.touched.user_id && stageFormik.errors?.user_id && (
                <div
                  style={{
                    color: "red",
                    fontSize: "12px",
                  }}
                >
                  {stageFormik.errors?.user_id}
                </div>
              )}
            </div>
          </div>

          <div className='d-flex'>
            <div className='col-md-6 '>
              <div className='form-group'>
                <label className='col-sm control-label'>Start Date*</label>
                <Calendar
                  value={stageFormik.values?.planned_start}
                  onChange={(e) =>
                    stageFormik.setFieldValue("planned_start", e.value)
                  }
                  showIcon
                  dateFormat='dd-mm-yy'
                  placeholder="dd/mm/yy"
                  readOnlyInput
                />
                {stageFormik.touched.planned_start &&
                  stageFormik.errors?.planned_start && (
                    <div
                      style={{
                        color: "red",
                        fontSize: "12px",
                      }}
                    >
                      {stageFormik.errors?.planned_start}
                    </div>
                  )}
              </div>
            </div>

            <div className='col-md-6 '>
              <div className='form-group'>
                <label className='col-sm control-label'>End Date*</label>
                <Calendar
                  value={stageFormik.values?.planned_end}
                  onChange={(e) =>
                    stageFormik.setFieldValue("planned_end", e.value)
                  }
                  showIcon
                  dateFormat='dd-mm-yy'
                  placeholder="dd/mm/yy"
                  readOnlyInput
                />
                {stageFormik.touched.planned_end &&
                  stageFormik.errors?.planned_end && (
                    <div
                      style={{
                        color: "red",
                        fontSize: "12px",
                      }}
                    >
                      {stageFormik.errors?.planned_end}
                    </div>
                  )}
              </div>
            </div>
          </div>
          <div className='form-group'>
            <div className='col-sm-12 col-sm-offset-2 text-right pt-2'>
              <button
                type='submit'
                id='button_2'
                className='btn btn-primary'
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </Dialog>

      {/* Dialog for Assign Supervisor form */}
      <Dialog
        header='Assign'
        visible={assignSupervisor}
        style={{ width: "35vw" }}
        onHide={() => {
          setAssignSupervisor(false);
          supervisorFormik.resetForm();
        }}
      >
        <form
          className='form-horizontal'
          onSubmit={supervisorFormik.handleSubmit}
        >
          <div className='form-group'>
            <label className='col-sm control-label'>Stages</label>
            <div className='col-sm-12'>
              <Select
                options={lists?.map((stage) => ({
                  value: stage?.id,
                  label: stage?.stage_name + "--" + stage?.production_order,
                }))}
                name='line_stage_ids'
                id='line_stage_ids'
                value={supervisorFormik.values?.line_stage_ids?.map(
                  (stage) => ({
                    value: stage?.value,
                    label: stage?.label,
                  })
                )}
                isMulti
                onChange={(selectedOptions) => {
                  supervisorFormik.setFieldValue(
                    "line_stage_ids",
                    selectedOptions?.map((option) => ({
                      value: option.value,
                      label: option.label,
                    }))
                  );
                }}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Ensure the menu appears above other elements
                  menu: (base) => ({ ...base, overflow: "visible" }), // Apply overflow visible to the menu
                }}
                menuPortalTarget={document.body} // Render the menu in body to avoid clipping
              />
              {supervisorFormik.touched.line_stage_ids &&
                supervisorFormik.errors.line_stage_ids && (
                  <div
                    style={{
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {supervisorFormik.errors.line_stage_ids}
                  </div>
                )}
            </div>
          </div>
          <div className='form-group'>
            <label className='col-sm control-label'>Supervisor</label>
            <div className='col-sm-12'>
              <Select
                options={supervisors}
                value={supervisorFormik.values?.user_id}
                onChange={(selectedOption) =>
                  supervisorFormik.setFieldValue("user_id", selectedOption)
                }
                name='user_id'
                id='user_id'
                placeholder='Select Supervisor'
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Ensure the menu appears above other elements
                  menu: (base) => ({ ...base, overflow: "visible" }), // Apply overflow visible to the menu
                }}
                menuPortalTarget={document.body} // Render the menu in body to avoid clipping
              />
              {supervisorFormik.touched?.user_id &&
                supervisorFormik.errors?.user_id && (
                  <div
                    style={{
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {supervisorFormik.errors?.user_id}
                  </div>
                )}
            </div>
          </div>
          <div className='form-group'>
            <div className='col-sm-12 col-sm-offset-2 text-right pt-2'>
              <button
                type='submit'
                id='button_2'
                className='btn btn-primary'
              >
                Assign
              </button>
            </div>
          </div>
        </form>
      </Dialog>

      {/* Data Table for stages */}
      <DataTable
        header={header}
        value={newList}
        size='small'
        editMode='row'
        onRowEditComplete={onRowEditComplete}
        selectionMode='checkbox'
        selection={selectedRows}
        onSelectionChange={(e) => setSelectedRows(e.value)}
        // expandedRows={expandedRows}
        // onRowToggle={(e) => {
        //   setExpandedRows(e.data);
        // }}
        // rowExpansionTemplate={rowExpansionTemplate}
        // paginator
        scrollable
        scrollHeight='400px'
        filters={filters}
        onFilter={(e) => setFilters(e.filters)}
        rows={7}
        dataKey='id'
        tableStyle={{minWidth: '1200px'}}
      >
        <Column
          selectionMode='multiple'
          headerStyle={{ width: "2%" }}
        />
        <Column
          field='production_order'
          body={renderProductionOrderBody}
          header='P.O.'
          bodyStyle={{ textAlign: "center" }}
          headerStyle={{ minWidth: "110px", paddingLeft: "35px" }}
          sortable
        />
        <Column
          field='stage_name'
          header='Stage'
          headerStyle={{ minWidth: "300px" }}
          sortable
        />
        <Column
          field='supervisor'
          header='Stage Supervisor'
          editor={(options) => supervisorEditor(options)}
          body={supervisorBodyTemplate}
          headerStyle={{ minWidth: "160px" }}
          // sortable
        />
        <Column
          field='new_planned_start'
          header='Planned Start'
          editor={PlannedStartEditor}
          body={PlannedStartTemplate}
          headerStyle={{ width: "8%" }}
          // bodyStyle={{ textAlign: "center" }}
          // sortable
        />

        <Column
          field='new_planned_end'
          header='Planned End'
          editor={PlannedEndEditor}
          body={PlannedEndTemplate}
          headerStyle={{ width: "8%" }}
          // bodyStyle={{ textAlign: "center" }}
          // sortable
        />

        <Column
          field='new_flags'
          header='Flags'
          editor={(options) => flagsEditor(options)}
          headerStyle={{ width: "15%" }}
          body={flagsBodyTemplate}
          // sortable
        />

        <Column
          field='new_status'
          header='Status'
          headerStyle={{ width: "5%", textAlign: "right", paddingLeft: "10px" }}
          bodyStyle={{ textAlign: "center" }}
          body={statusBodyTemplate}
          editor={(options) => statusEditor(options)}
          // sortable
        />
        <Column
          field='new_remarks'
          header='Remarks'
          editor={(options) => remarksEditor(options)}
          body={remarksBodyTemplate}
          bodyStyle={{ paddingLeft: "20px" }}
          headerStyle={{ minWidth: "120px", paddingLeft: "20px" }}
        />

        <Column
          field='new_qc'
          header='Inter QC'
          editor={(options) => qcEditor(options)}
          headerStyle={{ width: "6%" }}
          body={qcBodyTemplate}
          // bodyStyle={{ textAlign: "center" }}
          // sortable
        />

        {/* Edit Button */}
        <Column
          rowEditor={true}
          headerStyle={{ textAlign: "left", minWidth: "100px" }}
          bodyStyle={{ textAlign: "right" }}
        />
        {/* Delete Button */}
        <Column
          field='action'
          headerStyle={{ textAlign: "left", width: "1%" }}
        />
      </DataTable>
    </>
  );
};

export default DataTableStages;
