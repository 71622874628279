import React, { useEffect, useRef, useState } from "react";
import { useLoader } from "../../context/Loader/LoaderProvider";
import Loader from "../../context/Loader/Loader";
import { showErrorToast } from "../../utils/Toaster";
import jsPDF from "jspdf";
import "jspdf-autotable"; // Import autoTable plugin
import { TabView, TabPanel } from "primereact/tabview";
import { Calendar } from "primereact/calendar"; // Import Calendar component
import * as XLSX from "xlsx";
import axiosPrivate from "../../hooks/axiosPrivate";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FaRegFilePdf } from "react-icons/fa6";
import { FaRegFileExcel } from "react-icons/fa";
import { FcClearFilters } from "react-icons/fc";

const Reports = () => {
  const { isLoading, setIsLoading } = useLoader();
  const [jobsData, setJobsData] = useState([]);
  const [stageAmountData, setStageAmountData] = useState([]);
  const [isComponentReady, setIsComponentReady] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [customDateSelceted, setCustomDateSelceted] = useState(false);
  const dt = useRef(null);

  const fetchJobsData = async () => {
    try {
      setIsLoading(true);
      let response;
      if (fromDate && toDate) {
        const options = { day: "2-digit", month: "2-digit", year: "2-digit" };
        response = await axiosPrivate.post("reports/monthly_dispatch", {
          fromDate: new Date(fromDate).toLocaleDateString("en-GB", options),
          toDate: new Date(toDate).toLocaleDateString("en-GB", options),
        });
      } else {
        response = await axiosPrivate.post("reports/monthly_dispatch", {});
      }

      if (response.status === 200) {
        const Data = response?.data?.data || [];
        const orderNumMap = new Map();

        const SoAmountMap = new Map();
        const orderQtyMap = new Map();

        // Creating Map to calculate the total amount of one SO
        Data.forEach((line) => {
          if (SoAmountMap.has(line.OrderNum)) {
            SoAmountMap.set(
              line.OrderNum,
              SoAmountMap.get(line.OrderNum) +
                Number(line.IndianAmt.replaceAll(",", ""))
            );
          } else {
            SoAmountMap.set(
              line.OrderNum,
              Number(line.IndianAmt.replaceAll(",", ""))
            );
          }

          if (orderQtyMap.has(line.OrderNum)) {
            orderQtyMap.set(
              line.OrderNum,
              orderQtyMap.get(line.OrderNum) + Number(line.OrderQty)
            );
          } else {
            orderQtyMap.set(line.OrderNum, Number(line.OrderQty));
          }

        });
        
         Data.forEach((line) => {
           if (!orderNumMap.has(line.OrderNum)) {
             orderNumMap.set(line.OrderNum, {
               ...line,
               SOTotalAmount: SoAmountMap.get(line.OrderNum)
                 ? SoAmountMap.get(line.OrderNum)
                 : "-",
               TotalOrderQty: orderQtyMap.get(line.OrderNum) ?
                 orderQtyMap.get(line.OrderNum) : '-'
             });
           }
         });

        setJobsData([...orderNumMap.values()]);        
      }
    } catch (error) {
      if (error.response?.status !== 404) {
        showErrorToast(error?.message);
      } else if (error.response?.status === 404) {
        setJobsData([]);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchStageAmountData = async () => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get("reports/stage_wise_amount");

      if (response.status === 200) {
        setStageAmountData(response?.data?.data || []);
      }
    } catch (error) {
      if (error.response?.status !== 404) {
        showErrorToast(error?.message);
      } else if (error.response?.status === 404) {
        setStageAmountData([]);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchJobsData();
    fetchStageAmountData();
  }, []);

  useEffect(() => {
    setIsComponentReady(true);
  }, []);

 const exportPdf = () => {
   if (!isComponentReady || !dt.current) return;

   const doc = new jsPDF();
   const activeData = activeTabIndex === 0 ? jobsData : stageAmountData;

   const generateHeader = (currentPage, pageSize) => {
     doc.setFontSize(12); // Increased font size for the heading
     doc.setTextColor(40);
     doc.setFont("helvetica", "normal"); 
     if (currentPage > 1) {
       doc.setPage(currentPage);
     }
     doc.text(
       activeTabIndex === 0
         ? "Tempsens - Dispatch Report"
         : "Tempsens - Stage-wise Amount Report",
       pageSize.width / 2,
       17,
       { align: "center" }
     );

     if (fromDate && toDate && activeTabIndex === 0) {
       doc.text(
         `From: ${fromDate.toLocaleDateString(
           "en-GB"
         )} To: ${toDate.toLocaleDateString("en-GB")}`,
         10,
         22
       );
     }

     doc.text(
       `Total ${activeTabIndex === 0 ? "Jobs" : "Stages"}: ${
         activeData.length
       }`,
       pageSize.width - 10,
       22,
       {
         align: "right",
       }
     );

     doc.setFontSize(10);
     doc.text(`Page ${currentPage}`, pageSize.width / 2, pageSize.height - 10, {
       align: "center",
     });
   };

   const columns =
     activeTabIndex === 0
       ? [
           { title: "Sr. No.", dataKey: "index" },
           { title: "Order No.", dataKey: "OrderNum" },
           { title: "Customer Name", dataKey: "CustomerName" },
           { title: "Plant", dataKey: "Plant" },
           { title: "State", dataKey: "State" },
           { title: "Country", dataKey: "Country" },
           { title: "Total Order Qty.", dataKey: "OrderQty" },
           { title: "Total Amount", dataKey: "SOTotalAmount" },
         ]
       : [
           { title: "Stage Name", dataKey: "stage_name" },
           { title: "Stage Load", dataKey: "jobs_count" },
           { title: "Total Amount", dataKey: "total_IndianAmt" },
         ];

   const rows = activeData.map((row, index) => ({
     ...row,
     index: index + 1,
     OrderQty: row.TotalOrderQty ? row.TotalOrderQty : "-",
     SOTotalAmount: row.SOTotalAmount
       ? row.SOTotalAmount.toLocaleString("en-IN")
       : "-",
   }));

   let finalY = 30; // Starting Y position for the table

   doc.autoTable({
     head: [columns.map((column) => column.title)],
     body: rows.map((row) => columns.map((column) => row[column.dataKey])),
     startY: finalY,
     theme: "grid",
     margin: { top: 30 }, // Ensures there's enough space for the header
     styles: {
       overflow: "linebreak", // Ensure text doesn't overflow cells
     },
     bodyStyles: {
       valign: "top",
     },
     rowPageBreak: "avoid", // Avoid splitting rows between pages
     willDrawCell: function (data) {
       if (data.row.section === "body") {
         const remainingSpace = doc.internal.pageSize.height - data.cursor.y;
         const rowHeight = data.cell.height;
         if (rowHeight > remainingSpace) {
           finalY = 30; // Reset Y position for the new page
           doc.addPage();
           generateHeader(
             doc.internal.getNumberOfPages(),
             doc.internal.pageSize
           );
         }
       }
     },
     didDrawPage: function (data) {
       generateHeader(data.pageNumber, doc.internal.pageSize);
     },
   });

   doc.save(
     activeTabIndex === 0
       ? "dispatch_report.pdf"
       : "stagewise_amount_report.pdf"
   );
 };


  const exportExcel = () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const fileName =
      activeTabIndex === 0 ? "dispatch_data" : "stagewise_amount_data";
    const ws = XLSX.utils.json_to_sheet(
      activeTabIndex === 0 ? jobsData : stageAmountData
    );
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], { type: fileType });
    const url = window.URL.createObjectURL(data);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName + fileExtension;
    a.click();
  };

  const handleClearFilter = () => {
    setFromDate(null);
    setToDate(null);
   
      fetchJobsData();
      fetchStageAmountData();
   
    setCustomDateSelceted(false);
  };

  const renderHeader = () => {
    return (
      <div className='row d-flex justify-content-between align-items-center pl-2 pr-2 '>
        <div>
          <button
            className='btn btn-primary'
            onClick={exportPdf}
          >
            <FaRegFilePdf
              size={17}
              className='mr-1'
            />
            Export as PDF
          </button>
          <button
            className='btn btn-primary ml-2'
            onClick={exportExcel}
          >
            <FaRegFileExcel
              size={17}
              className='mr-1'
            />
            Export as Excel
          </button>
        </div>

        {activeTabIndex === 0 ? (
          <div>
            <div className='d-flex justify-content-between align-items-center row'  >
              <div className='d-flex flex-column col-md'>
                <span>From: </span>
                <Calendar
                  value={fromDate}
                  onChange={(e) => setFromDate(e.value)}
                  showIcon
                  readOnlyInput
                  dateFormat='dd-mm-yy'
                  placeholder='dd-mm-yyyy'
                />
                {!fromDate && customDateSelceted ? (
                  <div
                    style={{
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    Please select From Date!
                  </div>
                ) : null}
              </div>
              <div className='d-flex flex-column  col-md'>
                <span className='ml-2'>To: </span>
                <Calendar
                  value={toDate}
                  onChange={(e) => setToDate(e.value)}
                  readOnlyInput
                  showIcon
                  dateFormat='dd-mm-yy'
                  placeholder='dd-mm-yyyy'
                />
                {!toDate && customDateSelceted && (
                  <div
                    style={{
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    Please select to Date!
                  </div>
                )}
              </div>
              <div className="col-auto">
              <button
                className='btn btn-primary ml-2 mt-3'
                onClick={() => {
                  setCustomDateSelceted(true);
                  fetchJobsData();
                }}
                style={{ cursor: "pointer" }}
              >
                Apply
              </button>
              <FcClearFilters
                className=' ml-2 mt-3'
                size={25}
                onClick={handleClearFilter}
                style={{ cursor: "pointer" }}
                />
                </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  const header = renderHeader();

  const indexBodyTemplate = (rowData) => {
    return <span>{rowData.index}</span>;
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className='content'>
            <TabView
              activeIndex={activeTabIndex}
              onTabChange={(e) => setActiveTabIndex(e.index)}
            >
              <TabPanel header='Monthly Dispatch'>
                <div
                  className='table-responsive '
                  style={{ minHeight: `calc(100vh - 106px)` }}
                >
                  <DataTable
                    header={header}
                    value={jobsData}
                    style={{ overflow: "scroll" }}
                    editMode='row'
                    paginator
                    rowsPerPageOptions={[20, 40, 60, 80]}
                    paginatorTemplate='RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink'
                    currentPageReportTemplate='{first} to {last} of {totalRecords}'
                    rows={20}
                    size='small'
                    dataKey='id'
                    id='jobsTable'
                    ref={dt}
                    showGridlines
                    tableStyle={{ minWidth: "1400px" }}
                  >
                    <Column
                      field='OrderNum'
                      header='Sales Order No.'
                      // headerStyle={{ minWidth: "40px", paddingLeft: "1%" }}
                      sortable={true}
                    />

                    <Column
                      field='CustomerName'
                      header='Customer Name'
                      sortable={true}
                    />
                    <Column
                      field='Plant'
                      header='Plant'
                      sortable={true}
                    />
                    <Column
                      field='State'
                      header='State'
                      body={(rowData) => (rowData.State ? rowData.State : "-")}
                      sortable={true}
                    />
                    <Column
                      field='Country'
                      header='Country'
                      sortable={true}
                    />
                    <Column
                      field='TotalOrderQty'
                      header='Total OrderQty.'
                      sortable={true}
                    />

                    <Column
                      field='SOTotalAmount'
                      header='Total Amount'
                      body={(rowData) =>
                        rowData?.SOTotalAmount
                          ? rowData?.SOTotalAmount.toLocaleString("en-IN")
                          : "-"
                      }
                      sortable={true}
                    />
                  </DataTable>
                </div>
              </TabPanel>
              <TabPanel header='Stage-wise Amount'>
                <div
                  className='table-responsive'
                  style={{ minHeight: `calc(100vh - 106px)` }}
                >
                  <DataTable
                    header={header}
                    value={stageAmountData}
                    style={{ overflow: "scroll" }}
                    editMode='row'
                    paginator
                    rowsPerPageOptions={[20, 40, 60, 80]}
                    paginatorTemplate='RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink'
                    currentPageReportTemplate='{first} to {last} of {totalRecords}'
                    rows={20}
                    size='small'
                    dataKey='id'
                    id='jobsTable'
                    ref={dt}
                    showGridlines
                  >
                    <Column
                      field='stage_name'
                      header='Stage Name'
                      sortable={true}
                    />
                    <Column
                      field='jobs_count'
                      header='Stage Load(No. of Jobs)'
                      sortable={true}
                    />
                    <Column
                      field='total_IndianAmt'
                      header='Total Amount'
                      sortable={true}
                    />
                  </DataTable>
                </div>
              </TabPanel>
            </TabView>
          </div>
        </>
      )}
    </>
  );
};

export default Reports;
